import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Clients } from "src/context";
import Constant from "src/utils/Constant";

const ClientPortalDropdownMenu = ({
  anchorElUser,
  handleCloseUserMenu,
  openDP,
}) => {
  const navigate = useNavigate();

  const isInPage = (key) => {
    if (window.location.href.includes("/app/settings")) {
      navigate(key);
      navigate(0);
    } else {
      navigate(key);
    }
  };

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar-client"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={openDP}
      onClose={handleCloseUserMenu}
      elevation={0}
      PaperProps={{
        sx: {
          width: "284px",
          boxShadow: "0px 13px 30px rgba(0, 0, 0, 0.05)",
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid #EAECEF",
          mt: "8px",
        },
      }}
    >
      {/* menu header */}
      <ItemHeader />

      <Item
        name={"Account Settings"}
        onClick={() => {
          handleCloseUserMenu();
          isInPage("/client/settings");
        }}
      />

      <Item
        name={"Logout"}
        onClick={() => {
          handleCloseUserMenu();
          navigate("/client/logout");
        }}
      />
    </Menu>
  );
};

export default ClientPortalDropdownMenu;

const ItemHeader = ({}) => {
  const { client } = useContext(Clients);
  return (
    <MenuItem
      sx={{
        padding: "14px 20px",
        backgroundColor: "#F4F5F6",
        borderRadius: "12px",
        mb: "12px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box>
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: client?.image ? "#FFF" : "var(--bg-black)",
            }}
            alt={"Treffas AB Image"}
            src={Constant.imageUrl("clients", client?.image)}
          >
            {client?.firstname?.charAt().toUpperCase()}
          </Avatar>
        </Box>
        <Box sx={{ maxWidth: "130px" }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "20px",
              color: "#060A2D",
              textTransform: "capitalize",
              overflow: "hidden", // Prevents content from overflowing
              textOverflow: "ellipsis", // Adds ellipsis for overflowing text
              whiteSpace: "nowrap", // Prevents text wrapping
            }}
          >
            {client?.firstname} {client?.lastname}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

const Item = ({ name, onClick, color = "" }) => {
  return (
    <MenuItem
      onClick={() => {
        onClick();
      }}
      sx={{
        padding: "8px 6px 8px 12px",
        height: "36px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "6px",
        }}
      >
        <Box
          sx={{
            fontWeight: 500,
            fontSize: "15px",
            lineHeight: "20px",
            color: color ? color : "#353A3F",
          }}
        >
          {name}
        </Box>

        <Box>
          <ChevronRight size={14} color={color ? color : "#6E757C"} />
        </Box>
      </Box>
    </MenuItem>
  );
};
