const Itnl = {
  custom_languages: [
    // { label: "Abkhaz", value: "ab" },
    // { label: "Afar", value: "aa" },
    // { label: "Arabic", value: "ar" },
    { label: "English", value: "en" },
  ],

  languages: {
    ab: {
      name: "Abkhaz",
      nativeName: "аҧсуа",
    },
    aa: {
      name: "Afar",
      nativeName: "Afaraf",
    },
    af: {
      name: "Afrikaans",
      nativeName: "Afrikaans",
    },
    ak: {
      name: "Akan",
      nativeName: "Akan",
    },
    sq: {
      name: "Albanian",
      nativeName: "Shqip",
    },
    am: {
      name: "Amharic",
      nativeName: "አማርኛ",
    },
    ar: {
      name: "Arabic",
      nativeName: "العربية",
    },
    an: {
      name: "Aragonese",
      nativeName: "Aragonés",
    },
    hy: {
      name: "Armenian",
      nativeName: "Հայերեն",
    },
    as: {
      name: "Assamese",
      nativeName: "অসমীয়া",
    },
    av: {
      name: "Avaric",
      nativeName: "авар мацӀ, магӀарул мацӀ",
    },
    ae: {
      name: "Avestan",
      nativeName: "avesta",
    },
    ay: {
      name: "Aymara",
      nativeName: "aymar aru",
    },
    az: {
      name: "Azerbaijani",
      nativeName: "azərbaycan dili",
    },
    bm: {
      name: "Bambara",
      nativeName: "bamanankan",
    },
    ba: {
      name: "Bashkir",
      nativeName: "башҡорт теле",
    },
    eu: {
      name: "Basque",
      nativeName: "euskara, euskera",
    },
    be: {
      name: "Belarusian",
      nativeName: "Беларуская",
    },
    bn: {
      name: "Bengali",
      nativeName: "বাংলা",
    },
    bh: {
      name: "Bihari",
      nativeName: "भोजपुरी",
    },
    bi: {
      name: "Bislama",
      nativeName: "Bislama",
    },
    bs: {
      name: "Bosnian",
      nativeName: "bosanski jezik",
    },
    br: {
      name: "Breton",
      nativeName: "brezhoneg",
    },
    bg: {
      name: "Bulgarian",
      nativeName: "български език",
    },
    my: {
      name: "Burmese",
      nativeName: "ဗမာစာ",
    },
    ca: {
      name: "Catalan; Valencian",
      nativeName: "Català",
    },
    ch: {
      name: "Chamorro",
      nativeName: "Chamoru",
    },
    ce: {
      name: "Chechen",
      nativeName: "нохчийн мотт",
    },
    ny: {
      name: "Chichewa; Chewa; Nyanja",
      nativeName: "chiCheŵa, chinyanja",
    },
    zh: {
      name: "Chinese",
      nativeName: "中文 (Zhōngwén), 汉语, 漢語",
    },
    cv: {
      name: "Chuvash",
      nativeName: "чӑваш чӗлхи",
    },
    kw: {
      name: "Cornish",
      nativeName: "Kernewek",
    },
    co: {
      name: "Corsican",
      nativeName: "corsu, lingua corsa",
    },
    cr: {
      name: "Cree",
      nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
    },
    hr: {
      name: "Croatian",
      nativeName: "hrvatski",
    },
    cs: {
      name: "Czech",
      nativeName: "česky, čeština",
    },
    da: {
      name: "Danish",
      nativeName: "dansk",
    },
    dv: {
      name: "Divehi; Dhivehi; Maldivian;",
      nativeName: "ދިވެހި",
    },
    nl: {
      name: "Dutch",
      nativeName: "Nederlands, Vlaams",
    },
    en: {
      name: "English",
      nativeName: "English",
    },
    eo: {
      name: "Esperanto",
      nativeName: "Esperanto",
    },
    et: {
      name: "Estonian",
      nativeName: "eesti, eesti keel",
    },
    ee: {
      name: "Ewe",
      nativeName: "Eʋegbe",
    },
    fo: {
      name: "Faroese",
      nativeName: "føroyskt",
    },
    fj: {
      name: "Fijian",
      nativeName: "vosa Vakaviti",
    },
    fi: {
      name: "Finnish",
      nativeName: "suomi, suomen kieli",
    },
    fr: {
      name: "French",
      nativeName: "français, langue française",
    },
    ff: {
      name: "Fula; Fulah; Pulaar; Pular",
      nativeName: "Fulfulde, Pulaar, Pular",
    },
    gl: {
      name: "Galician",
      nativeName: "Galego",
    },
    ka: {
      name: "Georgian",
      nativeName: "ქართული",
    },
    de: {
      name: "German",
      nativeName: "Deutsch",
    },
    el: {
      name: "Greek, Modern",
      nativeName: "Ελληνικά",
    },
    gn: {
      name: "Guaraní",
      nativeName: "Avañeẽ",
    },
    gu: {
      name: "Gujarati",
      nativeName: "ગુજરાતી",
    },
    ht: {
      name: "Haitian; Haitian Creole",
      nativeName: "Kreyòl ayisyen",
    },
    ha: {
      name: "Hausa",
      nativeName: "Hausa, هَوُسَ",
    },
    he: {
      name: "Hebrew",
      nativeName: "עברית",
    },
    iw: {
      name: "Hebrew",
      nativeName: "עברית",
    },
    hz: {
      name: "Herero",
      nativeName: "Otjiherero",
    },
    hi: {
      name: "Hindi",
      nativeName: "हिन्दी, हिंदी",
    },
    ho: {
      name: "Hiri Motu",
      nativeName: "Hiri Motu",
    },
    hu: {
      name: "Hungarian",
      nativeName: "Magyar",
    },
    ia: {
      name: "Interlingua",
      nativeName: "Interlingua",
    },
    id: {
      name: "Indonesian",
      nativeName: "Bahasa Indonesia",
    },
    ie: {
      name: "Interlingue",
      nativeName: "Originally called Occidental; then Interlingue after WWII",
    },
    ga: {
      name: "Irish",
      nativeName: "Gaeilge",
    },
    ig: {
      name: "Igbo",
      nativeName: "Asụsụ Igbo",
    },
    ik: {
      name: "Inupiaq",
      nativeName: "Iñupiaq, Iñupiatun",
    },
    io: {
      name: "Ido",
      nativeName: "Ido",
    },
    is: {
      name: "Icelandic",
      nativeName: "Íslenska",
    },
    it: {
      name: "Italian",
      nativeName: "Italiano",
    },
    iu: {
      name: "Inuktitut",
      nativeName: "ᐃᓄᒃᑎᑐᑦ",
    },
    ja: {
      name: "Japanese",
      nativeName: "日本語 (にほんご／にっぽんご)",
    },
    jv: {
      name: "Javanese",
      nativeName: "basa Jawa",
    },
    kl: {
      name: "Kalaallisut, Greenlandic",
      nativeName: "kalaallisut, kalaallit oqaasii",
    },
    kn: {
      name: "Kannada",
      nativeName: "ಕನ್ನಡ",
    },
    kr: {
      name: "Kanuri",
      nativeName: "Kanuri",
    },
    ks: {
      name: "Kashmiri",
      nativeName: "कश्मीरी, كشميري‎",
    },
    kk: {
      name: "Kazakh",
      nativeName: "Қазақ тілі",
    },
    km: {
      name: "Khmer",
      nativeName: "ភាសាខ្មែរ",
    },
    ki: {
      name: "Kikuyu, Gikuyu",
      nativeName: "Gĩkũyũ",
    },
    rw: {
      name: "Kinyarwanda",
      nativeName: "Ikinyarwanda",
    },
    ky: {
      name: "Kirghiz, Kyrgyz",
      nativeName: "кыргыз тили",
    },
    kv: {
      name: "Komi",
      nativeName: "коми кыв",
    },
    kg: {
      name: "Kongo",
      nativeName: "KiKongo",
    },
    ko: {
      name: "Korean",
      nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
    },
    ku: {
      name: "Kurdish",
      nativeName: "Kurdî, كوردی‎",
    },
    kj: {
      name: "Kwanyama, Kuanyama",
      nativeName: "Kuanyama",
    },
    la: {
      name: "Latin",
      nativeName: "latine, lingua latina",
    },
    lb: {
      name: "Luxembourgish, Letzeburgesch",
      nativeName: "Lëtzebuergesch",
    },
    lg: {
      name: "Luganda",
      nativeName: "Luganda",
    },
    li: {
      name: "Limburgish, Limburgan, Limburger",
      nativeName: "Limburgs",
    },
    ln: {
      name: "Lingala",
      nativeName: "Lingála",
    },
    lo: {
      name: "Lao",
      nativeName: "ພາສາລາວ",
    },
    lt: {
      name: "Lithuanian",
      nativeName: "lietuvių kalba",
    },
    lu: {
      name: "Luba-Katanga",
      nativeName: "",
    },
    lv: {
      name: "Latvian",
      nativeName: "latviešu valoda",
    },
    gv: {
      name: "Manx",
      nativeName: "Gaelg, Gailck",
    },
    mk: {
      name: "Macedonian",
      nativeName: "македонски јазик",
    },
    mg: {
      name: "Malagasy",
      nativeName: "Malagasy fiteny",
    },
    ms: {
      name: "Malay",
      nativeName: "bahasa Melayu, بهاس ملايو‎",
    },
    ml: {
      name: "Malayalam",
      nativeName: "മലയാളം",
    },
    mt: {
      name: "Maltese",
      nativeName: "Malti",
    },
    mi: {
      name: "Māori",
      nativeName: "te reo Māori",
    },
    mr: {
      name: "Marathi (Marāṭhī)",
      nativeName: "मराठी",
    },
    mh: {
      name: "Marshallese",
      nativeName: "Kajin M̧ajeļ",
    },
    mn: {
      name: "Mongolian",
      nativeName: "монгол",
    },
    na: {
      name: "Nauru",
      nativeName: "Ekakairũ Naoero",
    },
    nv: {
      name: "Navajo, Navaho",
      nativeName: "Diné bizaad, Dinékʼehǰí",
    },
    nb: {
      name: "Norwegian Bokmål",
      nativeName: "Norsk bokmål",
    },
    nd: {
      name: "North Ndebele",
      nativeName: "isiNdebele",
    },
    ne: {
      name: "Nepali",
      nativeName: "नेपाली",
    },
    ng: {
      name: "Ndonga",
      nativeName: "Owambo",
    },
    nn: {
      name: "Norwegian Nynorsk",
      nativeName: "Norsk nynorsk",
    },
    no: {
      name: "Norwegian",
      nativeName: "Norsk",
    },
    ii: {
      name: "Nuosu",
      nativeName: "ꆈꌠ꒿ Nuosuhxop",
    },
    nr: {
      name: "South Ndebele",
      nativeName: "isiNdebele",
    },
    oc: {
      name: "Occitan",
      nativeName: "Occitan",
    },
    oj: {
      name: "Ojibwe, Ojibwa",
      nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
    },
    cu: {
      name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
      nativeName: "ѩзыкъ словѣньскъ",
    },
    om: {
      name: "Oromo",
      nativeName: "Afaan Oromoo",
    },
    or: {
      name: "Oriya",
      nativeName: "ଓଡ଼ିଆ",
    },
    os: {
      name: "Ossetian, Ossetic",
      nativeName: "ирон æвзаг",
    },
    pa: {
      name: "Panjabi, Punjabi",
      nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
    },
    pi: {
      name: "Pāli",
      nativeName: "पाऴि",
    },
    fa: {
      name: "Persian",
      nativeName: "فارسی",
    },
    pl: {
      name: "Polish",
      nativeName: "polski",
    },
    ps: {
      name: "Pashto, Pushto",
      nativeName: "پښتو",
    },
    pt: {
      name: "Portuguese",
      nativeName: "Português",
    },
    qu: {
      name: "Quechua",
      nativeName: "Runa Simi, Kichwa",
    },
    rm: {
      name: "Romansh",
      nativeName: "rumantsch grischun",
    },
    rn: {
      name: "Kirundi",
      nativeName: "kiRundi",
    },
    ro: {
      name: "Romanian, Moldavian, Moldovan",
      nativeName: "română",
    },
    ru: {
      name: "Russian",
      nativeName: "русский язык",
    },
    sa: {
      name: "Sanskrit (Saṁskṛta)",
      nativeName: "संस्कृतम्",
    },
    sc: {
      name: "Sardinian",
      nativeName: "sardu",
    },
    sd: {
      name: "Sindhi",
      nativeName: "सिन्धी, سنڌي، سندھی‎",
    },
    se: {
      name: "Northern Sami",
      nativeName: "Davvisámegiella",
    },
    sm: {
      name: "Samoan",
      nativeName: "gagana faa Samoa",
    },
    sg: {
      name: "Sango",
      nativeName: "yângâ tî sängö",
    },
    sr: {
      name: "Serbian",
      nativeName: "српски језик",
    },
    gd: {
      name: "Scottish Gaelic; Gaelic",
      nativeName: "Gàidhlig",
    },
    sn: {
      name: "Shona",
      nativeName: "chiShona",
    },
    si: {
      name: "Sinhala, Sinhalese",
      nativeName: "සිංහල",
    },
    sk: {
      name: "Slovak",
      nativeName: "slovenčina",
    },
    sl: {
      name: "Slovene",
      nativeName: "slovenščina",
    },
    so: {
      name: "Somali",
      nativeName: "Soomaaliga, af Soomaali",
    },
    st: {
      name: "Southern Sotho",
      nativeName: "Sesotho",
    },
    es: {
      name: "Spanish; Castilian",
      nativeName: "español, castellano",
    },
    su: {
      name: "Sundanese",
      nativeName: "Basa Sunda",
    },
    sw: {
      name: "Swahili",
      nativeName: "Kiswahili",
    },
    ss: {
      name: "Swati",
      nativeName: "SiSwati",
    },
    sv: {
      name: "Swedish",
      nativeName: "svenska",
    },
    ta: {
      name: "Tamil",
      nativeName: "தமிழ்",
    },
    te: {
      name: "Telugu",
      nativeName: "తెలుగు",
    },
    tg: {
      name: "Tajik",
      nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
    },
    th: {
      name: "Thai",
      nativeName: "ไทย",
    },
    ti: {
      name: "Tigrinya",
      nativeName: "ትግርኛ",
    },
    bo: {
      name: "Tibetan Standard, Tibetan, Central",
      nativeName: "བོད་ཡིག",
    },
    tk: {
      name: "Turkmen",
      nativeName: "Türkmen, Түркмен",
    },
    tl: {
      name: "Tagalog",
      nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
    },
    tn: {
      name: "Tswana",
      nativeName: "Setswana",
    },
    to: {
      name: "Tonga (Tonga Islands)",
      nativeName: "faka Tonga",
    },
    tr: {
      name: "Turkish",
      nativeName: "Türkçe",
    },
    ts: {
      name: "Tsonga",
      nativeName: "Xitsonga",
    },
    tt: {
      name: "Tatar",
      nativeName: "татарча, tatarça, تاتارچا‎",
    },
    tw: {
      name: "Twi",
      nativeName: "Twi",
    },
    ty: {
      name: "Tahitian",
      nativeName: "Reo Tahiti",
    },
    ug: {
      name: "Uighur, Uyghur",
      nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
    },
    uk: {
      name: "Ukrainian",
      nativeName: "українська",
    },
    ur: {
      name: "Urdu",
      nativeName: "اردو",
    },
    uz: {
      name: "Uzbek",
      nativeName: "zbek, Ўзбек, أۇزبېك‎",
    },
    ve: {
      name: "Venda",
      nativeName: "Tshivenḓa",
    },
    vi: {
      name: "Vietnamese",
      nativeName: "Tiếng Việt",
    },
    vo: {
      name: "Volapük",
      nativeName: "Volapük",
    },
    wa: {
      name: "Walloon",
      nativeName: "Walon",
    },
    cy: {
      name: "Welsh",
      nativeName: "Cymraeg",
    },
    wo: {
      name: "Wolof",
      nativeName: "Wollof",
    },
    fy: {
      name: "Western Frisian",
      nativeName: "Frysk",
    },
    xh: {
      name: "Xhosa",
      nativeName: "isiXhosa",
    },
    yi: {
      name: "Yiddish",
      nativeName: "ייִדיש",
    },
    yo: {
      name: "Yoruba",
      nativeName: "Yorùbá",
    },
    za: {
      name: "Zhuang, Chuang",
      nativeName: "Saɯ cueŋƅ, Saw cuengh",
    },
  },

  timezones: [
    {
      value: "Dateline Standard Time",
      abbr: "DST",
      offset: -12,
      isdst: false,
      label: "(UTC-12:00) International Date Line West",
      utc: ["Etc/GMT+12"],
    },
    {
      value: "UTC-11",
      abbr: "U",
      offset: -11,
      isdst: false,
      label: "(UTC-11:00) Coordinated Universal Time-11",
      utc: [
        "Etc/GMT+11",
        "Pacific/Midway",
        "Pacific/Niue",
        "Pacific/Pago_Pago",
      ],
    },
    {
      value: "Hawaiian Standard Time",
      abbr: "HST",
      offset: -10,
      isdst: false,
      label: "(UTC-10:00) Hawaii",
      utc: [
        "Etc/GMT+10",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Rarotonga",
        "Pacific/Tahiti",
      ],
    },
    {
      value: "Alaskan Standard Time",
      abbr: "AKDT",
      offset: -8,
      isdst: true,
      label: "(UTC-09:00) Alaska",
      utc: [
        "America/Anchorage",
        "America/Juneau",
        "America/Nome",
        "America/Sitka",
        "America/Yakutat",
      ],
    },
    {
      value: "Pacific Standard Time (Mexico)",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      label: "(UTC-08:00) Baja California",
      utc: ["America/Santa_Isabel"],
    },
    {
      value: "Pacific Daylight Time",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      label: "(UTC-07:00) Pacific Time (US & Canada)",
      utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
    },
    {
      value: "Pacific Standard Time",
      abbr: "PST",
      offset: -8,
      isdst: false,
      label: "(UTC-08:00) Pacific Time (US & Canada)",
      utc: [
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "PST8PDT",
      ],
    },
    {
      value: "US Mountain Standard Time",
      abbr: "UMST",
      offset: -7,
      isdst: false,
      label: "(UTC-07:00) Arizona",
      utc: [
        "America/Creston",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Hermosillo",
        "America/Phoenix",
        "America/Whitehorse",
        "Etc/GMT+7",
      ],
    },
    {
      value: "Mountain Standard Time (Mexico)",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      utc: ["America/Chihuahua", "America/Mazatlan"],
    },
    {
      value: "Mountain Standard Time",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      label: "(UTC-07:00) Mountain Time (US & Canada)",
      utc: [
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Denver",
        "America/Edmonton",
        "America/Inuvik",
        "America/Ojinaga",
        "America/Yellowknife",
        "MST7MDT",
      ],
    },
    {
      value: "Central America Standard Time",
      abbr: "CAST",
      offset: -6,
      isdst: false,
      label: "(UTC-06:00) Central America",
      utc: [
        "America/Belize",
        "America/Costa_Rica",
        "America/El_Salvador",
        "America/Guatemala",
        "America/Managua",
        "America/Tegucigalpa",
        "Etc/GMT+6",
        "Pacific/Galapagos",
      ],
    },
    {
      value: "Central Standard Time",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      label: "(UTC-06:00) Central Time (US & Canada)",
      utc: [
        "America/Chicago",
        "America/Indiana/Knox",
        "America/Indiana/Tell_City",
        "America/Matamoros",
        "America/Menominee",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Resolute",
        "America/Winnipeg",
        "CST6CDT",
      ],
    },
    {
      value: "Central Standard Time (Mexico)",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      utc: [
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey",
      ],
    },
    {
      value: "Canada Central Standard Time",
      abbr: "CCST",
      offset: -6,
      isdst: false,
      label: "(UTC-06:00) Saskatchewan",
      utc: ["America/Regina", "America/Swift_Current"],
    },
    {
      value: "SA Pacific Standard Time",
      abbr: "SPST",
      offset: -5,
      isdst: false,
      label: "(UTC-05:00) Bogota, Lima, Quito",
      utc: [
        "America/Bogota",
        "America/Cayman",
        "America/Coral_Harbour",
        "America/Eirunepe",
        "America/Guayaquil",
        "America/Jamaica",
        "America/Lima",
        "America/Panama",
        "America/Rio_Branco",
        "Etc/GMT+5",
      ],
    },
    {
      value: "Eastern Standard Time",
      abbr: "EDT",
      offset: -4,
      isdst: true,
      label: "(UTC-05:00) Eastern Time (US & Canada)",
      utc: [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto",
        "EST5EDT",
      ],
    },
    {
      value: "US Eastern Standard Time",
      abbr: "UEDT",
      offset: -4,
      isdst: true,
      label: "(UTC-05:00) Indiana (East)",
      utc: [
        "America/Indiana/Marengo",
        "America/Indiana/Vevay",
        "America/Indianapolis",
      ],
    },
    {
      value: "Venezuela Standard Time",
      abbr: "VST",
      offset: -4.5,
      isdst: false,
      label: "(UTC-04:30) Caracas",
      utc: ["America/Caracas"],
    },
    {
      value: "Paraguay Standard Time",
      abbr: "PYT",
      offset: -4,
      isdst: false,
      label: "(UTC-04:00) Asuncion",
      utc: ["America/Asuncion"],
    },
    {
      value: "Atlantic Standard Time",
      abbr: "ADT",
      offset: -3,
      isdst: true,
      label: "(UTC-04:00) Atlantic Time (Canada)",
      utc: [
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Moncton",
        "America/Thule",
        "Atlantic/Bermuda",
      ],
    },
    {
      value: "Central Brazilian Standard Time",
      abbr: "CBST",
      offset: -4,
      isdst: false,
      label: "(UTC-04:00) Cuiaba",
      utc: ["America/Campo_Grande", "America/Cuiaba"],
    },
    {
      value: "SA Western Standard Time",
      abbr: "SWST",
      offset: -4,
      isdst: false,
      label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      utc: [
        "America/Anguilla",
        "America/Antigua",
        "America/Aruba",
        "America/Barbados",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Curacao",
        "America/Dominica",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guyana",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lower_Princes",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Montserrat",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Santo_Domingo",
        "America/St_Barthelemy",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Tortola",
        "Etc/GMT+4",
      ],
    },
    {
      value: "Pacific SA Standard Time",
      abbr: "PSST",
      offset: -4,
      isdst: false,
      label: "(UTC-04:00) Santiago",
      utc: ["America/Santiago", "Antarctica/Palmer"],
    },
    {
      value: "Newfoundland Standard Time",
      abbr: "NDT",
      offset: -2.5,
      isdst: true,
      label: "(UTC-03:30) Newfoundland",
      utc: ["America/St_Johns"],
    },
    {
      value: "E. South America Standard Time",
      abbr: "ESAST",
      offset: -3,
      isdst: false,
      label: "(UTC-03:00) Brasilia",
      utc: ["America/Sao_Paulo"],
    },
    {
      value: "Argentina Standard Time",
      abbr: "AST",
      offset: -3,
      isdst: false,
      label: "(UTC-03:00) Buenos Aires",
      utc: [
        "America/Argentina/La_Rioja",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Buenos_Aires",
        "America/Catamarca",
        "America/Cordoba",
        "America/Jujuy",
        "America/Mendoza",
      ],
    },
    {
      value: "SA Eastern Standard Time",
      abbr: "SEST",
      offset: -3,
      isdst: false,
      label: "(UTC-03:00) Cayenne, Fortaleza",
      utc: [
        "America/Araguaina",
        "America/Belem",
        "America/Cayenne",
        "America/Fortaleza",
        "America/Maceio",
        "America/Paramaribo",
        "America/Recife",
        "America/Santarem",
        "Antarctica/Rothera",
        "Atlantic/Stanley",
        "Etc/GMT+3",
      ],
    },
    {
      value: "Greenland Standard Time",
      abbr: "GDT",
      offset: -3,
      isdst: true,
      label: "(UTC-03:00) Greenland",
      utc: ["America/Godthab"],
    },
    {
      value: "Montevideo Standard Time",
      abbr: "MST",
      offset: -3,
      isdst: false,
      label: "(UTC-03:00) Montevideo",
      utc: ["America/Montevideo"],
    },
    {
      value: "Bahia Standard Time",
      abbr: "BST",
      offset: -3,
      isdst: false,
      label: "(UTC-03:00) Salvador",
      utc: ["America/Bahia"],
    },
    {
      value: "UTC-02",
      abbr: "U",
      offset: -2,
      isdst: false,
      label: "(UTC-02:00) Coordinated Universal Time-02",
      utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
    },
    {
      value: "Mid-Atlantic Standard Time",
      abbr: "MDT",
      offset: -1,
      isdst: true,
      label: "(UTC-02:00) Mid-Atlantic - Old",
      utc: [],
    },
    {
      value: "Azores Standard Time",
      abbr: "ADT",
      offset: 0,
      isdst: true,
      label: "(UTC-01:00) Azores",
      utc: ["America/Scoresbysund", "Atlantic/Azores"],
    },
    {
      value: "Cape Verde Standard Time",
      abbr: "CVST",
      offset: -1,
      isdst: false,
      label: "(UTC-01:00) Cape Verde Is.",
      utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
    },
    {
      value: "Morocco Standard Time",
      abbr: "MDT",
      offset: 1,
      isdst: true,
      label: "(UTC) Casablanca",
      utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
    },
    {
      value: "UTC",
      abbr: "UTC",
      offset: 0,
      isdst: false,
      label: "(UTC) Coordinated Universal Time",
      utc: ["America/Danmarkshavn", "Etc/GMT"],
    },
    {
      value: "GMT Standard Time",
      abbr: "GMT",
      offset: 0,
      isdst: false,
      label: "(UTC) Edinburgh, London",
      utc: [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London",
      ],
    },
    {
      value: "British Summer Time",
      abbr: "BST",
      offset: 1,
      isdst: true,
      label: "(UTC+01:00) Edinburgh, London",
      utc: [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London",
      ],
    },

    {
      value: "GMT Standard Time",
      abbr: "GDT",
      offset: 1,
      isdst: true,
      label: "(UTC) Dublin, Lisbon",
      utc: [
        "Atlantic/Canary",
        "Atlantic/Faeroe",
        "Atlantic/Madeira",
        "Europe/Dublin",
        "Europe/Lisbon",
      ],
    },
    {
      value: "Greenwich Standard Time",
      abbr: "GST",
      offset: 0,
      isdst: false,
      label: "(UTC) Monrovia, Reykjavik",
      utc: [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Bamako",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Freetown",
        "Africa/Lome",
        "Africa/Monrovia",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Sao_Tome",
        "Atlantic/Reykjavik",
        "Atlantic/St_Helena",
      ],
    },
    {
      value: "W. Europe Standard Time",
      abbr: "WEDT",
      offset: 2,
      isdst: true,
      label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      utc: [
        "Arctic/Longyearbyen",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Gibraltar",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Monaco",
        "Europe/Oslo",
        "Europe/Rome",
        "Europe/San_Marino",
        "Europe/Stockholm",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Zurich",
      ],
    },
    {
      value: "Central Europe Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      utc: [
        "Europe/Belgrade",
        "Europe/Bratislava",
        "Europe/Budapest",
        "Europe/Ljubljana",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Tirane",
      ],
    },
    {
      value: "Romance Standard Time",
      abbr: "RDT",
      offset: 2,
      isdst: true,
      label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      utc: [
        "Africa/Ceuta",
        "Europe/Brussels",
        "Europe/Copenhagen",
        "Europe/Madrid",
        "Europe/Paris",
      ],
    },
    {
      value: "Central European Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      utc: [
        "Europe/Sarajevo",
        "Europe/Skopje",
        "Europe/Warsaw",
        "Europe/Zagreb",
      ],
    },
    {
      value: "W. Central Africa Standard Time",
      abbr: "WCAST",
      offset: 1,
      isdst: false,
      label: "(UTC+01:00) West Central Africa",
      utc: [
        "Africa/Algiers",
        "Africa/Bangui",
        "Africa/Brazzaville",
        "Africa/Douala",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Luanda",
        "Africa/Malabo",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Porto-Novo",
        "Africa/Tunis",
        "Etc/GMT-1",
      ],
    },
    {
      value: "Namibia Standard Time",
      abbr: "NST",
      offset: 1,
      isdst: false,
      label: "(UTC+01:00) Windhoek",
      utc: ["Africa/Windhoek"],
    },
    {
      value: "GTB Standard Time",
      abbr: "GDT",
      offset: 3,
      isdst: true,
      label: "(UTC+02:00) Athens, Bucharest",
      utc: [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
      ],
    },
    {
      value: "Middle East Standard Time",
      abbr: "MEDT",
      offset: 3,
      isdst: true,
      label: "(UTC+02:00) Beirut",
      utc: ["Asia/Beirut"],
    },
    {
      value: "Egypt Standard Time",
      abbr: "EST",
      offset: 2,
      isdst: false,
      label: "(UTC+02:00) Cairo",
      utc: ["Africa/Cairo"],
    },
    {
      value: "Syria Standard Time",
      abbr: "SDT",
      offset: 3,
      isdst: true,
      label: "(UTC+02:00) Damascus",
      utc: ["Asia/Damascus"],
    },
    {
      value: "E. Europe Standard Time",
      abbr: "EEDT",
      offset: 3,
      isdst: true,
      label: "(UTC+02:00) E. Europe",
      utc: [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Nicosia",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye",
      ],
    },
    {
      value: "South Africa Standard Time",
      abbr: "SAST",
      offset: 2,
      isdst: false,
      label: "(UTC+02:00) Harare, Pretoria",
      utc: [
        "Africa/Blantyre",
        "Africa/Bujumbura",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Kigali",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Etc/GMT-2",
      ],
    },
    {
      value: "FLE Standard Time",
      abbr: "FDT",
      offset: 3,
      isdst: true,
      label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      utc: [
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye",
      ],
    },
    {
      value: "Turkey Standard Time",
      abbr: "TDT",
      offset: 3,
      isdst: false,
      label: "(UTC+03:00) Istanbul",
      utc: ["Europe/Istanbul"],
    },
    {
      value: "Israel Standard Time",
      abbr: "JDT",
      offset: 3,
      isdst: true,
      label: "(UTC+02:00) Jerusalem",
      utc: ["Asia/Jerusalem"],
    },
    {
      value: "Libya Standard Time",
      abbr: "LST",
      offset: 2,
      isdst: false,
      label: "(UTC+02:00) Tripoli",
      utc: ["Africa/Tripoli"],
    },
    {
      value: "Jordan Standard Time",
      abbr: "JST",
      offset: 3,
      isdst: false,
      label: "(UTC+03:00) Amman",
      utc: ["Asia/Amman"],
    },
    {
      value: "Arabic Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      label: "(UTC+03:00) Baghdad",
      utc: ["Asia/Baghdad"],
    },
    {
      value: "Kaliningrad Standard Time",
      abbr: "KST",
      offset: 3,
      isdst: false,
      label: "(UTC+02:00) Kaliningrad",
      utc: ["Europe/Kaliningrad"],
    },
    {
      value: "Arab Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      label: "(UTC+03:00) Kuwait, Riyadh",
      utc: [
        "Asia/Aden",
        "Asia/Bahrain",
        "Asia/Kuwait",
        "Asia/Qatar",
        "Asia/Riyadh",
      ],
    },
    {
      value: "E. Africa Standard Time",
      abbr: "EAST",
      offset: 3,
      isdst: false,
      label: "(UTC+03:00) Nairobi",
      utc: [
        "Africa/Addis_Ababa",
        "Africa/Asmera",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Mogadishu",
        "Africa/Nairobi",
        "Antarctica/Syowa",
        "Etc/GMT-3",
        "Indian/Antananarivo",
        "Indian/Comoro",
        "Indian/Mayotte",
      ],
    },
    {
      value: "Moscow Standard Time",
      abbr: "MSK",
      offset: 3,
      isdst: false,
      label: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
      utc: [
        "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Minsk",
      ],
    },
    {
      value: "Samara Time",
      abbr: "SAMT",
      offset: 4,
      isdst: false,
      label: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
      utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
    },
    {
      value: "Iran Standard Time",
      abbr: "IDT",
      offset: 4.5,
      isdst: true,
      label: "(UTC+03:30) Tehran",
      utc: ["Asia/Tehran"],
    },
    {
      value: "Arabian Standard Time",
      abbr: "AST",
      offset: 4,
      isdst: false,
      label: "(UTC+04:00) Abu Dhabi, Muscat",
      utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
    },
    {
      value: "Azerbaijan Standard Time",
      abbr: "ADT",
      offset: 5,
      isdst: true,
      label: "(UTC+04:00) Baku",
      utc: ["Asia/Baku"],
    },
    {
      value: "Mauritius Standard Time",
      abbr: "MST",
      offset: 4,
      isdst: false,
      label: "(UTC+04:00) Port Louis",
      utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
    },
    {
      value: "Georgian Standard Time",
      abbr: "GET",
      offset: 4,
      isdst: false,
      label: "(UTC+04:00) Tbilisi",
      utc: ["Asia/Tbilisi"],
    },
    {
      value: "Caucasus Standard Time",
      abbr: "CST",
      offset: 4,
      isdst: false,
      label: "(UTC+04:00) Yerevan",
      utc: ["Asia/Yerevan"],
    },
    {
      value: "Afghanistan Standard Time",
      abbr: "AST",
      offset: 4.5,
      isdst: false,
      label: "(UTC+04:30) Kabul",
      utc: ["Asia/Kabul"],
    },
    {
      value: "West Asia Standard Time",
      abbr: "WAST",
      offset: 5,
      isdst: false,
      label: "(UTC+05:00) Ashgabat, Tashkent",
      utc: [
        "Antarctica/Mawson",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Dushanbe",
        "Asia/Oral",
        "Asia/Samarkand",
        "Asia/Tashkent",
        "Etc/GMT-5",
        "Indian/Kerguelen",
        "Indian/Maldives",
      ],
    },
    {
      value: "Yekaterinburg Time",
      abbr: "YEKT",
      offset: 5,
      isdst: false,
      label: "(UTC+05:00) Yekaterinburg",
      utc: ["Asia/Yekaterinburg"],
    },
    {
      value: "Pakistan Standard Time",
      abbr: "PKT",
      offset: 5,
      isdst: false,
      label: "(UTC+05:00) Islamabad, Karachi",
      utc: ["Asia/Karachi"],
    },
    {
      value: "India Standard Time",
      abbr: "IST",
      offset: 5.5,
      isdst: false,
      label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      utc: ["Asia/Kolkata", "Asia/Calcutta"],
    },
    {
      value: "Sri Lanka Standard Time",
      abbr: "SLST",
      offset: 5.5,
      isdst: false,
      label: "(UTC+05:30) Sri Jayawardenepura",
      utc: ["Asia/Colombo"],
    },
    {
      value: "Nepal Standard Time",
      abbr: "NST",
      offset: 5.75,
      isdst: false,
      label: "(UTC+05:45) Kathmandu",
      utc: ["Asia/Kathmandu"],
    },
    {
      value: "Central Asia Standard Time",
      abbr: "CAST",
      offset: 6,
      isdst: false,
      label: "(UTC+06:00) Nur-Sultan (Astana)",
      utc: [
        "Antarctica/Vostok",
        "Asia/Almaty",
        "Asia/Bishkek",
        "Asia/Qyzylorda",
        "Asia/Urumqi",
        "Etc/GMT-6",
        "Indian/Chagos",
      ],
    },
    {
      value: "Bangladesh Standard Time",
      abbr: "BST",
      offset: 6,
      isdst: false,
      label: "(UTC+06:00) Dhaka",
      utc: ["Asia/Dhaka", "Asia/Thimphu"],
    },
    {
      value: "Myanmar Standard Time",
      abbr: "MST",
      offset: 6.5,
      isdst: false,
      label: "(UTC+06:30) Yangon (Rangoon)",
      utc: ["Asia/Rangoon", "Indian/Cocos"],
    },
    {
      value: "SE Asia Standard Time",
      abbr: "SAST",
      offset: 7,
      isdst: false,
      label: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      utc: [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas",
      ],
    },
    {
      value: "N. Central Asia Standard Time",
      abbr: "NCAST",
      offset: 7,
      isdst: false,
      label: "(UTC+07:00) Novosibirsk",
      utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
    },
    {
      value: "China Standard Time",
      abbr: "CST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
    },
    {
      value: "North Asia Standard Time",
      abbr: "NAST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Krasnoyarsk",
      utc: ["Asia/Krasnoyarsk"],
    },
    {
      value: "Singapore Standard Time",
      abbr: "MPST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Kuala Lumpur, Singapore",
      utc: [
        "Asia/Brunei",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Singapore",
        "Etc/GMT-8",
      ],
    },

    // bug offset 12
    {
      value: "W. Australia Standard Time",
      abbr: "WAST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Perth",
      utc: ["Australia/Perth", "Antarctica/Casey"], // Antarctica/Casey bug sow offset of 12
    },

    {
      value: "Taipei Standard Time",
      abbr: "TST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Taipei",
      utc: ["Asia/Taipei"],
    },
    {
      value: "Ulaanbaatar Standard Time",
      abbr: "UST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Ulaanbaatar",
      utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
    },
    {
      value: "North Asia East Standard Time",
      abbr: "NAEST",
      offset: 8,
      isdst: false,
      label: "(UTC+08:00) Irkutsk",
      utc: ["Asia/Irkutsk"],
    },
    {
      value: "Japan Standard Time",
      abbr: "JST",
      offset: 9,
      isdst: false,
      label: "(UTC+09:00) Osaka, Sapporo, Tokyo",
      utc: [
        "Asia/Dili",
        "Asia/Jayapura",
        "Asia/Tokyo",
        "Etc/GMT-9",
        "Pacific/Palau",
      ],
    },
    {
      value: "Korea Standard Time",
      abbr: "KST",
      offset: 9,
      isdst: false,
      label: "(UTC+09:00) Seoul",
      utc: ["Asia/Pyongyang", "Asia/Seoul"],
    },
    {
      value: "Cen. Australia Standard Time",
      abbr: "CAST",
      offset: 9.5,
      isdst: false,
      label: "(UTC+09:30) Adelaide",
      utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
    },
    {
      value: "AUS Central Standard Time",
      abbr: "ACST",
      offset: 9.5,
      isdst: false,
      label: "(UTC+09:30) Darwin",
      utc: ["Australia/Darwin"],
    },
    {
      value: "E. Australia Standard Time",
      abbr: "EAST",
      offset: 10,
      isdst: false,
      label: "(UTC+10:00) Brisbane",
      utc: ["Australia/Brisbane", "Australia/Lindeman"],
    },
    {
      value: "AUS Eastern Standard Time",
      abbr: "AEST",
      offset: 10,
      isdst: false,
      label: "(UTC+10:00) Canberra, Melbourne, Sydney",
      utc: ["Australia/Melbourne", "Australia/Sydney"],
    },
    {
      value: "West Pacific Standard Time",
      abbr: "WPST",
      offset: 10,
      isdst: false,
      label: "(UTC+10:00) Guam, Port Moresby",
      utc: [
        "Antarctica/DumontDUrville",
        "Etc/GMT-10",
        "Pacific/Guam",
        "Pacific/Port_Moresby",
        "Pacific/Saipan",
        "Pacific/Truk",
      ],
    },
    {
      value: "Tasmania Standard Time",
      abbr: "TST",
      offset: 10,
      isdst: false,
      label: "(UTC+10:00) Hobart",
      utc: ["Australia/Currie", "Australia/Hobart"],
    },
    {
      value: "Yakutsk Standard Time",
      abbr: "YST",
      offset: 9,
      isdst: false,
      label: "(UTC+09:00) Yakutsk",
      utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
    },
    {
      value: "Central Pacific Standard Time",
      abbr: "CPST",
      offset: 11,
      isdst: false,
      label: "(UTC+11:00) Solomon Is., New Caledonia",
      utc: [
        "Antarctica/Macquarie",
        "Etc/GMT-11",
        "Pacific/Efate",
        "Pacific/Guadalcanal",
        "Pacific/Kosrae",
        "Pacific/Noumea",
        "Pacific/Ponape",
      ],
    },
    {
      value: "Vladivostok Standard Time",
      abbr: "VST",
      offset: 11,
      isdst: false,
      label: "(UTC+11:00) Vladivostok",
      utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
    },
    {
      value: "New Zealand Standard Time",
      abbr: "NZST",
      offset: 12,
      isdst: false,
      label: "(UTC+12:00) Auckland, Wellington",
      utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
    },
    {
      value: "UTC+12",
      abbr: "U",
      offset: 12,
      isdst: false,
      label: "(UTC+12:00) Coordinated Universal Time+12",
      utc: [
        "Etc/GMT-12",
        "Pacific/Funafuti",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Nauru",
        "Pacific/Tarawa",
        "Pacific/Wake",
        "Pacific/Wallis",
      ],
    },
    {
      value: "Fiji Standard Time",
      abbr: "FST",
      offset: 12,
      isdst: false,
      label: "(UTC+12:00) Fiji",
      utc: ["Pacific/Fiji"],
    },
    {
      value: "Magadan Standard Time",
      abbr: "MST",
      offset: 12,
      isdst: false,
      label: "(UTC+12:00) Magadan",
      utc: [
        "Asia/Anadyr",
        "Asia/Kamchatka",
        "Asia/Magadan",
        "Asia/Srednekolymsk",
      ],
    },
    {
      value: "Kamchatka Standard Time",
      abbr: "KDT",
      offset: 13,
      isdst: true,
      label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      utc: ["Asia/Kamchatka"],
    },
    {
      value: "Tonga Standard Time",
      abbr: "TST",
      offset: 13,
      isdst: false,
      label: "(UTC+13:00) Nuku'alofa",
      utc: [
        "Etc/GMT-13",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Tongatapu",
      ],
    },
    {
      value: "Samoa Standard Time",
      abbr: "SST",
      offset: 13,
      isdst: false,
      label: "(UTC+13:00) Samoa",
      utc: ["Pacific/Apia"],
    },
  ],

  country: [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Åland Islands", value: "Åland Islands" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "American Samoa", value: "American Samoa" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Anguilla", value: "Anguilla" },
    { label: "Antarctica", value: "Antarctica" },
    { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Aruba", value: "Aruba" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Bhutan", value: "Bhutan" },
    {
      label: "Bolivia",
      value: "Bolivia",
    },
    {
      label: "Bonaire, Sint Eustatius and Saba",
      value: "Bonaire, Sint Eustatius and Saba",
    },
    { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Bouvet Island", value: "Bouvet Island" },
    { label: "Brazil", value: "Brazil" },
    {
      label: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    { label: "Brunei Darussalam", value: "Brunei Darussalam" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cabo Verde", value: "Cabo Verde" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Cayman Islands", value: "Cayman Islands" },
    {
      label: "Central African Republic",
      value: "Central African Republic",
    },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Christmas Island", value: "Christmas Island" },
    {
      label: "Cocos (Keeling) Islands",
      value: "Cocos (Keeling) Islands",
    },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    {
      label: "Congo",
      value: "Congo",
    },
    { label: "Cook Islands", value: "Cook Islands" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Curaçao", value: "Curaçao" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czechia", value: "Czechia" },
    { label: "Côte d'Ivoire", value: "Côte d'Ivoire" },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    { label: "Eswatini", value: "Eswatini" },
    { label: "Ethiopia", value: "Ethiopia" },
    {
      label: "Falkland Islands (the) [Malvinas]",
      value: "Falkland Islands (the) [Malvinas]",
    },
    { label: "Faroe Islands", value: "Faroe Islands" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "French Guiana", value: "French Guiana" },
    { label: "French Polynesia", value: "French Polynesia" },
    {
      label: "French Southern Territories",
      value: "French Southern Territories",
    },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Gibraltar", value: "Gibraltar" },
    { label: "Greece", value: "Greece" },
    { label: "Greenland", value: "Greenland" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guadeloupe", value: "Guadeloupe" },
    { label: "Guam", value: "Guam" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guernsey", value: "Guernsey" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    {
      label: "Heard Island and McDonald Islands",
      value: "Heard Island and McDonald Islands",
    },
    { label: "Holy See", value: "Holy See" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    {
      label: "Iran",
      value: "Iran",
    },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Isle of Man", value: "Isle of Man" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jersey", value: "Jersey" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    {
      label: "Korea",
      value: "Korea",
    },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    {
      label: "Lao People's Democratic Republic",
      value: "Lao People's Democratic Republic",
    },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Macao", value: "Macao" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marshall Islands", value: "Marshall Islands" },
    { label: "Martinique", value: "Martinique" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mayotte", value: "Mayotte" },
    { label: "Mexico", value: "Mexico" },
    {
      label: "Micronesia",
      value: "Micronesia",
    },
    { label: "Moldova", value: "Moldova" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Montserrat", value: "Montserrat" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar", value: "Myanmar" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "New Caledonia", value: "New Caledonia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "Niue", value: "Niue" },
    { label: "Norfolk Island", value: "Norfolk Island" },
    {
      label: "Northern Mariana Islands",
      value: "Northern Mariana Islands",
    },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Palestine", value: "Palestine" },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Pitcairn", value: "Pitcairn" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Puerto Rico", value: "Puerto Rico" },
    { label: "Qatar", value: "Qatar" },
    {
      label: "Republic of North Macedonia",
      value: "Republic of North Macedonia",
    },
    { label: "Romania", value: "Romania" },
    { label: "Russian Federation", value: "Russian Federation" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Réunion", value: "Réunion" },
    { label: "Saint Barthélemy", value: "Saint Barthélemy" },
    {
      label: "Saint Helena, Ascension and Tristan da Cunha",
      value: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { label: "Saint Lucia", value: "Saint Lucia" },
    {
      label: "Saint Martin",
      value: "Saint Martin",
    },
    { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Sint Maarten (Dutch part)", value: "Sint Maarten (Dutch part)" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    {
      label: "South Georgia and the South Sandwich Islands",
      value: "South Georgia and the South Sandwich Islands",
    },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
    {
      label: "Taiwan",
      value: "Taiwan",
    },
    { label: "Tajikistan", value: "Tajikistan" },
    {
      label: "Tanzania, United Republic of",
      value: "Tanzania, United Republic of",
    },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tokelau", value: "Tokelau" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    {
      label: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
    },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    {
      label: "United Arab Emirates",
      value: "United Arab Emirates",
    },
    {
      label: "United Kingdom of Great Britain and Northern Ireland",
      value: "United Kingdom of Great Britain and Northern Ireland",
    },
    {
      label: "United States Minor Outlying Islands",
      value: "United States Minor Outlying Islands",
    },
    {
      label: "United States of America",
      value: "United States of America",
    },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    {
      label: "Venezuela",
      value: "Venezuela",
    },
    { label: "Viet Nam", value: "Viet Nam" },
    { label: "Virgin Islands (British)", value: "Virgin Islands (British)" },
    { label: "Virgin Islands (U.S.)", value: "Virgin Islands (U.S.)" },
    { label: "Wallis and Futuna", value: "Wallis and Futuna" },
    { label: "Western Sahara", value: "Western Sahara" },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ],

  dialcodes: [
    {
      name: "Afghanistan",
      dialCode: "+93",
      isoCode: "AF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
    },
    {
      name: "Aland Islands",
      dialCode: "+358",
      isoCode: "AX",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
    },
    {
      name: "Albania",
      dialCode: "+355",
      isoCode: "AL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
    },
    {
      name: "Algeria",
      dialCode: "+213",
      isoCode: "DZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
    },
    {
      name: "American Samoa",
      dialCode: "+1684",
      isoCode: "AS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
    },
    {
      name: "Andorra",
      dialCode: "+376",
      isoCode: "AD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
    },
    {
      name: "Angola",
      dialCode: "+244",
      isoCode: "AO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
    },
    {
      name: "Anguilla",
      dialCode: "+1264",
      isoCode: "AI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
    },
    {
      name: "Antarctica",
      dialCode: "+672",
      isoCode: "AQ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
    },
    {
      name: "Antigua and Barbuda",
      dialCode: "+1268",
      isoCode: "AG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
    },
    {
      name: "Argentina",
      dialCode: "+54",
      isoCode: "AR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
    },
    {
      name: "Armenia",
      dialCode: "+374",
      isoCode: "AM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
    },
    {
      name: "Aruba",
      dialCode: "+297",
      isoCode: "AW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
    },
    {
      name: "Ascension Island",
      dialCode: "+247",
      isoCode: "AC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ac.svg",
    },
    {
      name: "Australia",
      dialCode: "+61",
      isoCode: "AU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
    },
    {
      name: "Austria",
      dialCode: "+43",
      isoCode: "AT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
    },
    {
      name: "Azerbaijan",
      dialCode: "+994",
      isoCode: "AZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
    },
    {
      name: "Bahamas",
      dialCode: "+1242",
      isoCode: "BS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
    },
    {
      name: "Bahrain",
      dialCode: "+973",
      isoCode: "BH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
    },
    {
      name: "Bangladesh",
      dialCode: "+880",
      isoCode: "BD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
    },
    {
      name: "Barbados",
      dialCode: "+1246",
      isoCode: "BB",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
    },
    {
      name: "Belarus",
      dialCode: "+375",
      isoCode: "BY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
    },
    {
      name: "Belgium",
      dialCode: "+32",
      isoCode: "BE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
    },
    {
      name: "Belize",
      dialCode: "+501",
      isoCode: "BZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
    },
    {
      name: "Benin",
      dialCode: "+229",
      isoCode: "BJ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
    },
    {
      name: "Bermuda",
      dialCode: "+1441",
      isoCode: "BM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bm.svg",
    },
    {
      name: "Bhutan",
      dialCode: "+975",
      isoCode: "BT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
    },
    {
      name: "Bolivia",
      dialCode: "+591",
      isoCode: "BO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
    },
    {
      name: "Bosnia and Herzegovina",
      dialCode: "+387",
      isoCode: "BA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
    },
    {
      name: "Botswana",
      dialCode: "+267",
      isoCode: "BW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
    },
    {
      name: "Brazil",
      dialCode: "+55",
      isoCode: "BR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
    },
    {
      name: "British Indian Ocean Territory",
      dialCode: "+246",
      isoCode: "IO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/io.svg",
    },
    {
      name: "Brunei Darussalam",
      dialCode: "+673",
      isoCode: "BN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
    },
    {
      name: "Bulgaria",
      dialCode: "+359",
      isoCode: "BG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
    },
    {
      name: "Burkina Faso",
      dialCode: "+226",
      isoCode: "BF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
    },
    {
      name: "Burundi",
      dialCode: "+257",
      isoCode: "BI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
    },
    {
      name: "Cambodia",
      dialCode: "+855",
      isoCode: "KH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
    },
    {
      name: "Cameroon",
      dialCode: "+237",
      isoCode: "CM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
    },
    {
      name: "Canada",
      dialCode: "+1",
      isoCode: "CA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
    },
    {
      name: "Cape Verde",
      dialCode: "+238",
      isoCode: "CV",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cv.svg",
    },
    {
      name: "Cayman Islands",
      dialCode: "+1345",
      isoCode: "KY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ky.svg",
    },
    {
      name: "Central African Republic",
      dialCode: "+236",
      isoCode: "CF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
    },
    {
      name: "Chad",
      dialCode: "+235",
      isoCode: "TD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
    },
    {
      name: "Chile",
      dialCode: "+56",
      isoCode: "CL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
    },
    {
      name: "China",
      dialCode: "+86",
      isoCode: "CN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
    },
    {
      name: "Christmas Island",
      dialCode: "+61",
      isoCode: "CX",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cx.svg",
    },
    {
      name: "Cocos (Keeling) Islands",
      dialCode: "+61",
      isoCode: "CC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cc.svg",
    },
    {
      name: "Colombia",
      dialCode: "+57",
      isoCode: "CO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
    },
    {
      name: "Comoros",
      dialCode: "+269",
      isoCode: "KM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
    },
    {
      name: "Congo",
      dialCode: "+242",
      isoCode: "CG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
    },
    {
      name: "Cook Islands",
      dialCode: "+682",
      isoCode: "CK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ck.svg",
    },
    {
      name: "Costa Rica",
      dialCode: "+506",
      isoCode: "CR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
    },
    {
      name: "Croatia",
      dialCode: "+385",
      isoCode: "HR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
    },
    {
      name: "Cuba",
      dialCode: "+53",
      isoCode: "CU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
    },
    {
      name: "Cyprus",
      dialCode: "+357",
      isoCode: "CY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
    },
    {
      name: "Czech Republic",
      dialCode: "+420",
      isoCode: "CZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
    },
    {
      name: "Democratic Republic of the Congo",
      dialCode: "+243",
      isoCode: "CD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
    },
    {
      name: "Denmark",
      dialCode: "+45",
      isoCode: "DK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
    },
    {
      name: "Djibouti",
      dialCode: "+253",
      isoCode: "DJ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
    },
    {
      name: "Dominica",
      dialCode: "+1767",
      isoCode: "DM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
    },
    {
      name: "Dominican Republic",
      dialCode: "+1849",
      isoCode: "DO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
    },
    {
      name: "Ecuador",
      dialCode: "+593",
      isoCode: "EC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
    },
    {
      name: "Egypt",
      dialCode: "+20",
      isoCode: "EG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
    },
    {
      name: "El Salvador",
      dialCode: "+503",
      isoCode: "SV",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
    },
    {
      name: "Equatorial Guinea",
      dialCode: "+240",
      isoCode: "GQ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
    },
    {
      name: "Eritrea",
      dialCode: "+291",
      isoCode: "ER",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
    },
    {
      name: "Estonia",
      dialCode: "+372",
      isoCode: "EE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
    },
    {
      name: "Eswatini",
      dialCode: "+268",
      isoCode: "SZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
    },
    {
      name: "Ethiopia",
      dialCode: "+251",
      isoCode: "ET",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
    },
    {
      name: "Falkland Islands (Malvinas)",
      dialCode: "+500",
      isoCode: "FK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/fk.svg",
    },
    {
      name: "Faroe Islands",
      dialCode: "+298",
      isoCode: "FO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/fo.svg",
    },
    {
      name: "Fiji",
      dialCode: "+679",
      isoCode: "FJ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
    },
    {
      name: "Finland",
      dialCode: "+358",
      isoCode: "FI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
    },
    {
      name: "France",
      dialCode: "+33",
      isoCode: "FR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
    },
    {
      name: "French Guiana",
      dialCode: "+594",
      isoCode: "GF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
    },
    {
      name: "French Polynesia",
      dialCode: "+689",
      isoCode: "PF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
    },
    {
      name: "Gabon",
      dialCode: "+241",
      isoCode: "GA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
    },
    {
      name: "Gambia",
      dialCode: "+220",
      isoCode: "GM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
    },
    {
      name: "Georgia",
      dialCode: "+995",
      isoCode: "GE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
    },
    {
      name: "Germany",
      dialCode: "+49",
      isoCode: "DE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
    },
    {
      name: "Ghana",
      dialCode: "+233",
      isoCode: "GH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
    },
    {
      name: "Gibraltar",
      dialCode: "+350",
      isoCode: "GI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
    },
    {
      name: "Greece",
      dialCode: "+30",
      isoCode: "GR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
    },
    {
      name: "Greenland",
      dialCode: "+299",
      isoCode: "GL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
    },
    {
      name: "Grenada",
      dialCode: "+1473",
      isoCode: "GD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
    },
    {
      name: "Guadeloupe",
      dialCode: "+590",
      isoCode: "GP",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gp.svg",
    },
    {
      name: "Guam",
      dialCode: "+1671",
      isoCode: "GU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
    },
    {
      name: "Guatemala",
      dialCode: "+502",
      isoCode: "GT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
    },
    {
      name: "Guernsey",
      dialCode: "+44-1481",
      isoCode: "GG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
    },
    {
      name: "Guinea",
      dialCode: "+224",
      isoCode: "GN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
    },
    {
      name: "Guinea-Bissau",
      dialCode: "+245",
      isoCode: "GW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
    },
    {
      name: "Guyana",
      dialCode: "+592",
      isoCode: "GY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
    },
    {
      name: "Haiti",
      dialCode: "+509",
      isoCode: "HT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
    },
    {
      name: "Holy See (Vatican City State)",
      dialCode: "+379",
      isoCode: "VA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
    },
    {
      name: "Honduras",
      dialCode: "+504",
      isoCode: "HN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
    },
    {
      name: "Hong Kong",
      dialCode: "+852",
      isoCode: "HK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
    },
    {
      name: "Hungary",
      dialCode: "+36",
      isoCode: "HU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
    },
    {
      name: "Iceland",
      dialCode: "+354",
      isoCode: "IS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
    },
    {
      name: "India",
      dialCode: "+91",
      isoCode: "IN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
    },
    {
      name: "Indonesia",
      dialCode: "+62",
      isoCode: "ID",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
    },
    {
      name: "Iran",
      dialCode: "+98",
      isoCode: "IR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
    },
    {
      name: "Iraq",
      dialCode: "+964",
      isoCode: "IQ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
    },
    {
      name: "Ireland",
      dialCode: "+353",
      isoCode: "IE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
    },
    {
      name: "Isle of Man",
      dialCode: "+44-1624",
      isoCode: "IM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/im.svg",
    },
    {
      name: "Israel",
      dialCode: "+972",
      isoCode: "IL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
    },
    {
      name: "Italy",
      dialCode: "+39",
      isoCode: "IT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
    },
    {
      name: "Ivory Coast / Cote d'Ivoire",
      dialCode: "+225",
      isoCode: "CI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
    },
    {
      name: "Jamaica",
      dialCode: "+1876",
      isoCode: "JM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
    },
    {
      name: "Japan",
      dialCode: "+81",
      isoCode: "JP",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
    },
    {
      name: "Jersey",
      dialCode: "+44-1534",
      isoCode: "JE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
    },
    {
      name: "Jordan",
      dialCode: "+962",
      isoCode: "JO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
    },
    {
      name: "Kazakhstan",
      dialCode: "+77",
      isoCode: "KZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
    },
    {
      name: "Kenya",
      dialCode: "+254",
      isoCode: "KE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
    },
    {
      name: "Kiribati",
      dialCode: "+686",
      isoCode: "KI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dialCode: "+850",
      isoCode: "KP",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
    },
    {
      name: "Korea, Republic of South Korea",
      dialCode: "+82",
      isoCode: "KR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
    },
    {
      name: "Kosovo",
      dialCode: "+383",
      isoCode: "XK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/xk.svg",
    },
    {
      name: "Kuwait",
      dialCode: "+965",
      isoCode: "KW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
    },
    {
      name: "Kyrgyzstan",
      dialCode: "+996",
      isoCode: "KG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
    },
    {
      name: "Laos",
      dialCode: "+856",
      isoCode: "LA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
    },
    {
      name: "Latvia",
      dialCode: "+371",
      isoCode: "LV",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
    },
    {
      name: "Lebanon",
      dialCode: "+961",
      isoCode: "LB",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
    },
    {
      name: "Lesotho",
      dialCode: "+266",
      isoCode: "LS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
    },
    {
      name: "Liberia",
      dialCode: "+231",
      isoCode: "LR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
    },
    {
      name: "Libya",
      dialCode: "+218",
      isoCode: "LY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
    },
    {
      name: "Liechtenstein",
      dialCode: "+423",
      isoCode: "LI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
    },
    {
      name: "Lithuania",
      dialCode: "+370",
      isoCode: "LT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
    },
    {
      name: "Luxembourg",
      dialCode: "+352",
      isoCode: "LU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
    },
    {
      name: "Macau",
      dialCode: "+853",
      isoCode: "MO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mo.svg",
    },
    {
      name: "Madagascar",
      dialCode: "+261",
      isoCode: "MG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
    },
    {
      name: "Malawi",
      dialCode: "+265",
      isoCode: "MW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
    },
    {
      name: "Malaysia",
      dialCode: "+60",
      isoCode: "MY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
    },
    {
      name: "Maldives",
      dialCode: "+960",
      isoCode: "MV",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
    },
    {
      name: "Mali",
      dialCode: "+223",
      isoCode: "ML",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
    },
    {
      name: "Malta",
      dialCode: "+356",
      isoCode: "MT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
    },
    {
      name: "Marshall Islands",
      dialCode: "+692",
      isoCode: "MH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
    },
    {
      name: "Martinique",
      dialCode: "+596",
      isoCode: "MQ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
    },
    {
      name: "Mauritania",
      dialCode: "+222",
      isoCode: "MR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
    },
    {
      name: "Mauritius",
      dialCode: "+230",
      isoCode: "MU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
    },
    {
      name: "Mayotte",
      dialCode: "+262",
      isoCode: "YT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/yt.svg",
    },
    {
      name: "Mexico",
      dialCode: "+52",
      isoCode: "MX",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      dialCode: "+691",
      isoCode: "FM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
    },
    {
      name: "Moldova",
      dialCode: "+373",
      isoCode: "MD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
    },
    {
      name: "Monaco",
      dialCode: "+377",
      isoCode: "MC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
    },
    {
      name: "Mongolia",
      dialCode: "+976",
      isoCode: "MN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
    },
    {
      name: "Montenegro",
      dialCode: "+382",
      isoCode: "ME",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
    },
    {
      name: "Montserrat",
      dialCode: "+1664",
      isoCode: "MS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
    },
    {
      name: "Morocco",
      dialCode: "+212",
      isoCode: "MA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
    },
    {
      name: "Mozambique",
      dialCode: "+258",
      isoCode: "MZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
    },
    {
      name: "Myanmar",
      dialCode: "+95",
      isoCode: "MM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
    },
    {
      name: "Namibia",
      dialCode: "+264",
      isoCode: "NA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
    },
    {
      name: "Nauru",
      dialCode: "+674",
      isoCode: "NR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
    },
    {
      name: "Nepal",
      dialCode: "+977",
      isoCode: "NP",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
    },
    {
      name: "Netherlands",
      dialCode: "+31",
      isoCode: "NL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
    },
    {
      name: "Netherlands Antilles",
      dialCode: "+599",
      isoCode: "AN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/an.svg",
    },
    {
      name: "New Caledonia",
      dialCode: "+687",
      isoCode: "NC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/nc.svg",
    },
    {
      name: "New Zealand",
      dialCode: "+64",
      isoCode: "NZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
    },
    {
      name: "Nicaragua",
      dialCode: "+505",
      isoCode: "NI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
    },
    {
      name: "Niger",
      dialCode: "+227",
      isoCode: "NE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
    },
    {
      name: "Nigeria",
      dialCode: "+234",
      isoCode: "NG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
    },
    {
      name: "Niue",
      dialCode: "+683",
      isoCode: "NU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/nu.svg",
    },
    {
      name: "Norfolk Island",
      dialCode: "+672",
      isoCode: "NF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
    },
    {
      name: "North Macedonia",
      dialCode: "+389",
      isoCode: "MK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mk.svg",
    },
    {
      name: "Northern Mariana Islands",
      dialCode: "+1670",
      isoCode: "MP",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
    },
    {
      name: "Norway",
      dialCode: "+47",
      isoCode: "NO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
    },
    {
      name: "Oman",
      dialCode: "+968",
      isoCode: "OM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
    },
    {
      name: "Pakistan",
      dialCode: "+92",
      isoCode: "PK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
    },
    {
      name: "Palau",
      dialCode: "+680",
      isoCode: "PW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
    },
    {
      name: "Palestine",
      dialCode: "+970",
      isoCode: "PS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ps.svg",
    },
    {
      name: "Panama",
      dialCode: "+507",
      isoCode: "PA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
    },
    {
      name: "Papua New Guinea",
      dialCode: "+675",
      isoCode: "PG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
    },
    {
      name: "Paraguay",
      dialCode: "+595",
      isoCode: "PY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
    },
    {
      name: "Peru",
      dialCode: "+51",
      isoCode: "PE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
    },
    {
      name: "Philippines",
      dialCode: "+63",
      isoCode: "PH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
    },
    {
      name: "Pitcairn",
      dialCode: "+872",
      isoCode: "PN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pn.svg",
    },
    {
      name: "Poland",
      dialCode: "+48",
      isoCode: "PL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
    },
    {
      name: "Portugal",
      dialCode: "+351",
      isoCode: "PT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
    },
    {
      name: "Puerto Rico",
      dialCode: "+1939",
      isoCode: "PR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
    },
    {
      name: "Qatar",
      dialCode: "+974",
      isoCode: "QA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
    },
    {
      name: "Reunion",
      dialCode: "+262",
      isoCode: "RE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/re.svg",
    },
    {
      name: "Romania",
      dialCode: "+40",
      isoCode: "RO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
    },
    {
      name: "Russia",
      dialCode: "+7",
      isoCode: "RU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
    },
    {
      name: "Rwanda",
      dialCode: "+250",
      isoCode: "RW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
    },
    {
      name: "Saint Barthelemy",
      dialCode: "+590",
      isoCode: "BL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/bl.svg",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dialCode: "+290",
      isoCode: "SH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
    },
    {
      name: "Saint Kitts and Nevis",
      dialCode: "+1869",
      isoCode: "KN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
    },
    {
      name: "Saint Lucia",
      dialCode: "+1758",
      isoCode: "LC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
    },
    {
      name: "Saint Martin",
      dialCode: "+590",
      isoCode: "MF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/mf.svg",
    },
    {
      name: "Saint Pierre and Miquelon",
      dialCode: "+508",
      isoCode: "PM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/pm.svg",
    },
    {
      name: "Saint Vincent and the Grenadines",
      dialCode: "+1784",
      isoCode: "VC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
    },
    {
      name: "Samoa",
      dialCode: "+685",
      isoCode: "WS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
    },
    {
      name: "San Marino",
      dialCode: "+378",
      isoCode: "SM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
    },
    {
      name: "Sao Tome and Principe",
      dialCode: "+239",
      isoCode: "ST",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
    },
    {
      name: "Saudi Arabia",
      dialCode: "+966",
      isoCode: "SA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
    },
    {
      name: "Senegal",
      dialCode: "+221",
      isoCode: "SN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
    },
    {
      name: "Serbia",
      dialCode: "+381",
      isoCode: "RS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
    },
    {
      name: "Seychelles",
      dialCode: "+248",
      isoCode: "SC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
    },
    {
      name: "Sierra Leone",
      dialCode: "+232",
      isoCode: "SL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
    },
    {
      name: "Singapore",
      dialCode: "+65",
      isoCode: "SG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
    },
    {
      name: "Sint Maarten",
      dialCode: "+1721",
      isoCode: "SX",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sx.svg",
    },
    {
      name: "Slovakia",
      dialCode: "+421",
      isoCode: "SK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
    },
    {
      name: "Slovenia",
      dialCode: "+386",
      isoCode: "SI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
    },
    {
      name: "Solomon Islands",
      dialCode: "+677",
      isoCode: "SB",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
    },
    {
      name: "Somalia",
      dialCode: "+252",
      isoCode: "SO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
    },
    {
      name: "South Africa",
      dialCode: "+27",
      isoCode: "ZA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dialCode: "+500",
      isoCode: "GS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gs.svg",
    },
    {
      name: "South Sudan",
      dialCode: "+211",
      isoCode: "SS",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
    },
    {
      name: "Spain",
      dialCode: "+34",
      isoCode: "ES",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
    },
    {
      name: "Sri Lanka",
      dialCode: "+94",
      isoCode: "LK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
    },
    {
      name: "Sudan",
      dialCode: "+249",
      isoCode: "SD",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
    },
    {
      name: "Suriname",
      dialCode: "+597",
      isoCode: "SR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
    },
    {
      name: "Svalbard and Jan Mayen",
      dialCode: "+47",
      isoCode: "SJ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sj.svg",
    },
    {
      name: "Sweden",
      dialCode: "+46",
      isoCode: "SE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
    },
    {
      name: "Switzerland",
      dialCode: "+41",
      isoCode: "CH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
    },
    {
      name: "Syrian Arab Republic",
      dialCode: "+963",
      isoCode: "SY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
    },
    {
      name: "Taiwan",
      dialCode: "+886",
      isoCode: "TW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
    },
    {
      name: "Tajikistan",
      dialCode: "+992",
      isoCode: "TJ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      dialCode: "+255",
      isoCode: "TZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
    },
    {
      name: "Thailand",
      dialCode: "+66",
      isoCode: "TH",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
    },
    {
      name: "Timor-Leste",
      dialCode: "+670",
      isoCode: "TL",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
    },
    {
      name: "Togo",
      dialCode: "+228",
      isoCode: "TG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
    },
    {
      name: "Tokelau",
      dialCode: "+690",
      isoCode: "TK",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tk.svg",
    },
    {
      name: "Tonga",
      dialCode: "+676",
      isoCode: "TO",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
    },
    {
      name: "Trinidad and Tobago",
      dialCode: "+1868",
      isoCode: "TT",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
    },
    {
      name: "Tunisia",
      dialCode: "+216",
      isoCode: "TN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
    },
    {
      name: "Turkey",
      dialCode: "+90",
      isoCode: "TR",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
    },
    {
      name: "Turkmenistan",
      dialCode: "+993",
      isoCode: "TM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
    },
    {
      name: "Turks and Caicos Islands",
      dialCode: "+1649",
      isoCode: "TC",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tc.svg",
    },
    {
      name: "Tuvalu",
      dialCode: "+688",
      isoCode: "TV",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
    },
    {
      name: "Uganda",
      dialCode: "+256",
      isoCode: "UG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
    },
    {
      name: "Ukraine",
      dialCode: "+380",
      isoCode: "UA",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
    },
    {
      name: "United Arab Emirates",
      dialCode: "+971",
      isoCode: "AE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
    },
    {
      name: "United Kingdom",
      dialCode: "+44",
      isoCode: "GB",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
    },
    {
      name: "United States",
      dialCode: "+1",
      isoCode: "US",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
    },
    {
      name: "United States Minor Outlying Islands",
      dialCode: "+246",
      isoCode: "UMI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/umi.svg",
    },
    {
      name: "Uruguay",
      dialCode: "+598",
      isoCode: "UY",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
    },
    {
      name: "Uzbekistan",
      dialCode: "+998",
      isoCode: "UZ",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
    },
    {
      name: "Vanuatu",
      dialCode: "+678",
      isoCode: "VU",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      dialCode: "+58",
      isoCode: "VE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
    },
    {
      name: "Vietnam",
      dialCode: "+84",
      isoCode: "VN",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
    },
    {
      name: "Virgin Islands, British",
      dialCode: "+1284",
      isoCode: "VG",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/vg.svg",
    },
    {
      name: "Virgin Islands, U.S.",
      dialCode: "+1340",
      isoCode: "VI",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/vi.svg",
    },
    {
      name: "Wallis and Futuna",
      dialCode: "+681",
      isoCode: "WF",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/wf.svg",
    },
    {
      name: "Yemen",
      dialCode: "+967",
      isoCode: "YE",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
    },
    {
      name: "Zambia",
      dialCode: "+260",
      isoCode: "ZM",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/zm.svg",
    },
    {
      name: "Zimbabwe",
      dialCode: "+263",
      isoCode: "ZW",
      flag: "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
    },
  ],

  getDailCode: (dialCode) => {
    return Itnl.dialcodes.filter((x) => x.dialCode === dialCode)[0];
  },

  getDailCodeByIso: (isoCode) => {
    return Itnl.dialcodes.filter((x) => x.isoCode === isoCode)[0];
  },
};

export default Itnl;
