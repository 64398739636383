import moment from "moment";
import momentTimezone from "moment-timezone";
import Helper from "./Helper";

let format = "MM-DD-YYYY hh:mm A";
let dateFormat = "YYYY/MM/DD";
let dateTimeFormat = "YYYY/MM/DD HH:mm";

const Booking = {
  _dateandtime: {
    isDayEnable: (daysAvailable, day) => {
      /** check calendar date if includes day ***/
      switch (day.toLowerCase()) {
        case "monday":
          return !daysAvailable.includes("monday");

        case "tuesday":
          return !daysAvailable.includes("tuesday");

        case "wednesday":
          return !daysAvailable.includes("wednesday");

        case "thursday":
          return !daysAvailable.includes("thursday");

        case "friday":
          return !daysAvailable.includes("friday");

        case "saturday":
          return !daysAvailable.includes("saturday");

        case "sunday":
          return !daysAvailable.includes("sunday");

        default:
          return null;
      }
    },

    isTimePassToday: (time, coachTimezone = null, selectedTimezone = null) => {
      // let _today = moment(new Date(), format); // must convert to coach timezone
      // let today = momentTimezone().tz(selectedTimezone); // must convert to coach timezone
      // let xstart = moment(new Date(time.start), format);

      // if (today.valueOf() >= xstart.valueOf()) return true;
      // return false;
      const timeStartMLFormat = moment(time.start).format("YYYY/MM/DD HH:mm");

      const __today = momentTimezone(new Date(), Helper.zeroTimezone).tz(
        coachTimezone
      );

      const today = momentTimezone(__today, format);

      const ___timestart = moment(timeStartMLFormat, "YYYY-MM-DD HH:mm").format(
        "YYYY-MM-DD HH:mm"
      );

      let utc_starttime = momentTimezone
        .tz(___timestart, selectedTimezone)
        .utc();

      // let client_starttime = utc_starttime.clone().tz(selectedTimezone);
      let coach_starttime = utc_starttime.clone().tz(coachTimezone);

      if (today.isSameOrAfter(coach_starttime)) {
        return true;
      }

      return false;
    },

    isTimeBlocked: (
      blocked,
      time,
      selectedTimezone = null,
      coachTimezone = null,
      selectedOffer = null
    ) => {
      // if (Object.entries(blocked).length === 0) return false;

      let xstart = moment(new Date(time.start), format);
      let xend = moment(new Date(time.end), format);

      let blocked_dates = selectedOffer?.custom_options?.blocked ?? [];
      let special_dates = selectedOffer?.custom_options?.special ?? [];
      if (blocked_dates.length > 0) {
        for (const el of blocked_dates) {
          const inputFormat = "YYYY-MM-DD HH:mm";
          const isBlockTypeDate = el?.type === "date" ? true : false;

          let startDate = isBlockTypeDate
            ? el.time_start
            : el.date + " " + el.time_start;
          let endDate = isBlockTypeDate
            ? el.time_end
            : el.date + " " + el.time_end;

          const sourceTimezone = coachTimezone;
          const targetTimezone = selectedTimezone;

          const clientBlockedTimeStart = moment
            .tz(
              moment(new Date(startDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD HH:mm");

          const clientBlockedTimeEnd = moment
            .tz(
              moment(new Date(endDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD HH:mm");

          if (
            xstart.isSame(clientBlockedTimeStart) ||
            xend.isSame(clientBlockedTimeStart) ||
            xstart.isSame(clientBlockedTimeEnd) ||
            xend.isSame(clientBlockedTimeEnd) ||
            xstart.isBetween(clientBlockedTimeStart, clientBlockedTimeEnd) ||
            xend.isBetween(clientBlockedTimeStart, clientBlockedTimeEnd)
          ) {
            console.log("blocked here...");
            return true;
          }
        }
      }

      // special is now holiday date and blocked coach calendar
      if (special_dates.length > 0) {
        for (const el of special_dates) {
          const inputFormat = "YYYY-MM-DD HH:mm";
          const isHolidayTypeDate = el?.type === "date" ? true : false;

          let startDate = isHolidayTypeDate
            ? el.time_start
            : el.date + " " + el.time_start;
          let endDate = isHolidayTypeDate
            ? el.time_end
            : el.date + " " + el.time_end;

          const sourceTimezone = coachTimezone;
          const targetTimezone = selectedTimezone;

          const clientHolidayTimeStart = moment
            .tz(
              moment(new Date(startDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD HH:mm");

          const clientHolidayTimeEnd = moment
            .tz(
              moment(new Date(endDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD HH:mm");

          if (
            xstart.isSame(clientHolidayTimeStart) ||
            xend.isSame(clientHolidayTimeStart) ||
            xstart.isSame(clientHolidayTimeEnd) ||
            xend.isSame(clientHolidayTimeEnd) ||
            xstart.isBetween(clientHolidayTimeStart, clientHolidayTimeEnd) ||
            xend.isBetween(clientHolidayTimeStart, clientHolidayTimeEnd)
          ) {
            console.log("holiday here...");
            return true;
          }
        }
      }

      return false;
    },

    isTimeNotAvailable: (time, takenDate, offer, timezone) => {
      if (takenDate?.length === 0) return false;

      let xstart = moment(new Date(time.start), format);

      let sessionType =
        offer?.custom_options?.sessions?.configuration?.type ?? "individual";

      if (sessionType === "individual") {
        let xx = takenDate?.filter((td) => {
          let takendate = momentTimezone.tz(td.date, Helper.zeroTimezone);
          let takendate_bytimezone = takendate
            .clone()
            .tz(timezone)
            .format(format);

          return moment(takendate_bytimezone, format).isSame(
            moment(new Date(xstart), format)
          );
        });

        return xx.length > 0 ? true : false;
      } else {
        let appcount = takenDate?.reduce(
          (initAppointmentCount, appointment) => {
            let takendate = momentTimezone.tz(
              appointment.date,
              Helper.zeroTimezone
            );
            let takendate_bytimezone = takendate
              .clone()
              .tz(timezone)
              .format(format);

            return moment(takendate_bytimezone, format).isSame(
              moment(new Date(xstart), format)
            )
              ? initAppointmentCount + 1
              : initAppointmentCount;
          },
          0
        );

        return appcount >=
          parseInt(offer?.custom_options?.sessions?.configuration.participants)
          ? true
          : false;
      }
    },

    isTimeBreakOff: (time, timezone) => {
      let date = moment(new Date(time.start), dateFormat).format(dateFormat);

      const breakoff = time?.break?.time ?? [];
      const hasBreakOff = breakoff.length > 0 ? true : false;

      if (hasBreakOff) {
        for (const breaked of breakoff) {
          let breaked_starttime = momentTimezone
            .tz(new Date(`${date} ${breaked.time_start}`), timezone)
            .format(dateTimeFormat);

          let break_off_endtime = momentTimezone
            .tz(new Date(`${date} ${breaked.time_end}`), timezone)
            .format(dateTimeFormat);

          let isTaken = Booking._dateandtime.checkTimeIsBetweenStartAndEnd(
            breaked_starttime,
            break_off_endtime,
            time
          );

          if (isTaken) {
            return true;
          }
        }
      }

      return false;
    },

    isTimeSameOnSelectedDate: (time, date) => {
      /* this function hides all underflow and overflow dates given by negative and positive timezone*/
      let _seldate = moment(new Date(date)).format(dateFormat);
      let _seltime = moment(new Date(time.start)).format(dateFormat);

      let check = moment(_seldate).isSame(_seltime);

      return check;
    },

    isDateBetweenTwoDates: (date, start, end) => {
      const datetime = moment(date, dateTimeFormat);
      const startDatetime = moment(start, dateTimeFormat);
      const endDatetime = moment(end, dateTimeFormat);

      const datetimeMs = datetime.valueOf();
      const startDatetimeMs = startDatetime.valueOf();
      const endDatetimeMs = endDatetime.valueOf();

      return datetimeMs >= startDatetimeMs && datetimeMs <= endDatetimeMs;
    },

    checkTimeIsBetweenStartAndEnd: (start_datetime, end_datetime, time) => {
      let start = moment(start_datetime).format(dateTimeFormat);
      let end = moment(end_datetime).format(dateTimeFormat);

      let availability_datestart = moment(new Date(time.start)).format(
        dateTimeFormat
      );
      let availability_dateend = moment(new Date(time.end)).format(
        dateTimeFormat
      );

      let isEventStartBetween = Booking._dateandtime.isDateBetweenTwoDates(
        start,
        availability_datestart,
        availability_dateend
      );

      let isEventEndBetween = Booking._dateandtime.isDateBetweenTwoDates(
        end,
        availability_datestart,
        availability_dateend
      );

      let isTimeStartBetween = Booking._dateandtime.isDateBetweenTwoDates(
        availability_datestart,
        start,
        end
      );

      let isTimeEndBetween = Booking._dateandtime.isDateBetweenTwoDates(
        availability_dateend,
        start,
        end
      );

      return isEventStartBetween ||
        isEventEndBetween ||
        isTimeStartBetween ||
        isTimeEndBetween
        ? true
        : false;
    },

    isTimeBookedFromOtherPackage: (time, allUpcomingAppts, timezone, offer) => {
      let events = allUpcomingAppts?.events ?? [];
      let UTC = Helper.zeroTimezone;

      if (events.length > 0) {
        for (const event of events) {
          let eventStart = moment(event.start_datetime);
          if (event.timezone) {
            eventStart = momentTimezone.tz(event.start_datetime, UTC);
          }

          let eventEnd = moment(event.end_datetime);
          if (event.timezone) {
            eventEnd = momentTimezone.tz(event.end_datetime, UTC);
          }

          let start_datetime = eventStart.clone().tz(timezone);
          let end_datetime = eventEnd.clone().tz(timezone);

          let isTaken = Booking._dateandtime.checkTimeIsBetweenStartAndEnd(
            start_datetime,
            end_datetime,
            time
          );

          if (isTaken) {
            return true;
          }
        }
      }

      return false;
    },

    isDateHasUnderOrOverFlow: (list, date) => {
      if (list.length > 0) {
        let currentdate = moment(date, dateFormat).format(dateFormat);
        let overflowdate = moment(list[0].start, dateFormat).format(dateFormat);

        return moment(currentdate, dateFormat).isSame(
          moment(overflowdate, dateFormat)
        );
      }

      return false;
    },

    shouldDisabledDate: (date, overFlowTime, underFlowTime, selectedOffer) => {
      let availableDays = [];

      if (Booking._dateandtime.isDateHasUnderOrOverFlow(overFlowTime, date)) {
        return false;
      }

      if (Booking._dateandtime.isDateHasUnderOrOverFlow(underFlowTime, date)) {
        return false;
      }

      // check for special date and push to sessionlist
      let special = selectedOffer?.custom_options?.special ?? [];
      if (special.length > 0) {
        special?.forEach((special) => {
          if (moment(special.date).isSame(date)) {
            availableDays.push(
              moment(special.date).format("dddd").toLowerCase()
            );
          }
        });
      }

      let day = moment(date, "dddd").format("dddd");

      if (selectedOffer?.custom_options?.available.length > 0) {
        for (const available of selectedOffer?.custom_options?.available) {
          availableDays.push(available.name);
        }

        return Booking._dateandtime.isDayEnable(availableDays, day);
      }
    },

    handleSelectedTime: async (
      time,
      userMinimumBookingNotice,
      userdateFormat,
      userTimeFormat,
      setSessionSelectedTime,
      nextButtonRef,
      setError,
      t
    ) => {
      let error = [];
      let minimumBookingNotice = parseInt(userMinimumBookingNotice);
      let coachDateTimeFormat = `${userdateFormat} ${userTimeFormat}`;
      let timeNow = moment(new Date());

      let minimumBooking = moment(
        new Date(time.start),
        coachDateTimeFormat
      ).subtract(minimumBookingNotice, "hours");

      if (minimumBookingNotice > 0) {
        if (timeNow.isAfter(minimumBooking)) {
          setError("time", {
            message: t("booking.error.required.minimum_booking", {
              minimumBookingNotice,
            }),
            required: true,
          });
          error = "error-selected-time-is-not-available";
        }
      }

      if (error.length > 0) {
        console.log("error:", error);
        return;
      }

      await setSessionSelectedTime({
        start: time.start,
        end: time.end,
      });

      if (nextButtonRef?.current) {
        nextButtonRef?.current?.click();
      }
    },

    handleBlockedDate: (date, offer, setBlockTimeOnSelectedDate) => {
      let seldate = moment(date, dateFormat).format(dateFormat);
      let calendardate = moment(seldate, dateFormat);

      let blocked = offer?.custom_options?.blocked ?? [];
      let holiday = offer?.custom_options?.special ?? [];

      if (blocked.length > 0) {
        blocked.forEach((block, key) => {
          let blckdate = moment(block.date, dateFormat).format(dateFormat);
          if (calendardate.isSame(blckdate)) {
            setBlockTimeOnSelectedDate({
              type: block?.type ?? "time",
              date: seldate,
              time_end: blocked[key].time_end,
              time_start: blocked[key].time_start,
            });
          }
        });
      }

      if (holiday.length > 0) {
        holiday.forEach((hol, key) => {
          let holidate = moment(hol.date, dateFormat).format(dateFormat);
          if (calendardate.isSame(holidate)) {
            setBlockTimeOnSelectedDate({
              type: hol?.type ?? "time",
              date: seldate,
              time_end: holiday[key].time_end,
              time_start: holiday[key].time_start,
            });
          }
        });
      }
    },

    generateSessionTime: (
      selectedTime,
      duration,
      date,
      selectedTimezone,
      coachDetails,
      appointmentInterval,
      listofOverFlowDates,
      listofOverflow,
      listofUnderFlowDates,
      listofUnderflow,
      listOfAvailableDates
    ) => {
      let today = moment(date, dateFormat).format(dateFormat);
      let startDate = `${today} ${selectedTime?.start}`;
      let endDate = `${today} ${selectedTime?.end}`;

      var coachStartDatetime = moment(
        new Date(startDate),
        dateTimeFormat
      ).format(dateTimeFormat);
      var coachEndDatetime = moment(new Date(endDate), dateTimeFormat).format(
        dateTimeFormat
      );

      var coachstartTime = momentTimezone.tz(
        coachStartDatetime,
        dateTimeFormat,
        coachDetails?.timezone_utc
      );

      var coachendTime = momentTimezone.tz(
        coachEndDatetime,
        dateTimeFormat,
        coachDetails?.timezone_utc
      );

      var clientStartDateTime = coachstartTime
        .clone()
        .tz(selectedTimezone)
        .format(dateTimeFormat);

      var clientEndDateTime = coachendTime
        .clone()
        .tz(selectedTimezone)
        .format(dateTimeFormat);

      var difference =
        new Date(clientEndDateTime).getTime() -
        new Date(clientStartDateTime).getTime(); // This will give difference in milliseconds

      /** get minutes of two dates **/
      var numberOfSessions = Math.round(difference / 60000) / duration;

      let timeOfSession = [];

      /** generates time of sessions base on availabitty **/
      for (let i = 0; i < numberOfSessions; i++) {
        if (timeOfSession.length > 0) {
          let enddatetime = moment(
            timeOfSession[timeOfSession.length - 1].next,
            dateTimeFormat
          )
            .add(duration, "minutes")
            .format(dateTimeFormat);

          let nextdatetime = moment(enddatetime, dateTimeFormat)
            .add(appointmentInterval, "minutes")
            .format(dateTimeFormat);

          if (
            parseInt(new Date(enddatetime).getTime()) >=
            parseInt(new Date(clientEndDateTime).getTime())
          ) {
            // console.log(`time is over enddate:`, enddatetime);
          } else {
            let startdatetime = moment(
              timeOfSession[timeOfSession.length - 1].next,
              dateTimeFormat
            ).format(dateTimeFormat);
            timeOfSession.push({
              start: startdatetime,
              end: enddatetime,
              next: nextdatetime,
              break: selectedTime?.break,
            });
          }
        } else {
          let enddatetime = moment(clientStartDateTime, dateTimeFormat)
            .add(duration, "minutes")
            .format(dateTimeFormat);

          let nextdatetime = moment(enddatetime, dateTimeFormat)
            .add(appointmentInterval, "minutes")
            .format(dateTimeFormat);

          timeOfSession.push({
            start: clientStartDateTime,
            end: enddatetime,
            next: nextdatetime,
            break: selectedTime?.break,
          });
        }
      }

      let tommorow = coachendTime.add(1, "day").format(dateFormat);
      let overflowStartTime = timeOfSession.filter((xdate) => {
        let xstarttime = moment(xdate.start).format(dateFormat);
        return Boolean(
          moment(xstarttime, dateFormat).isSame(moment(tommorow, dateFormat))
        );
      });

      let yesterday = coachendTime.subtract(2, "days").format(dateFormat);

      let underflowStartTime = timeOfSession.filter((xdate) => {
        let xstarttime = moment(xdate.start).format(dateFormat);
        return Boolean(
          moment(xstarttime, dateFormat).isSame(moment(yesterday, dateFormat))
        );
      });

      if (overflowStartTime.length > 0) {
        listofOverFlowDates.push(tommorow);

        listofOverflow[tommorow] = overflowStartTime;
      }

      if (underflowStartTime.length > 0) {
        listofUnderFlowDates.push(yesterday);

        listofUnderflow[yesterday] = underflowStartTime;
      }

      if (timeOfSession.length > 0) {
        listOfAvailableDates[today] = timeOfSession;
        // listOfAvailableDates[today] = timeOfSession;
      }
    },

    getHolidayDates: (start, end) => {
      let startDate = new Date(start);
      let endDate = new Date(end);
      let dates = [];

      while (startDate <= endDate) {
        dates.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }

      return dates;
    },

    getDayEnableInPackage: (date, offer) => {
      let availableDays = [];

      // check for special date and push to sessionlist
      // special day is now holiday and blocked calendar
      // let special = offer?.custom_options?.special ?? [];
      // if (special.length > 0) {
      //   special?.forEach((special) => {
      //     const isSpecialDateType = special?.type === "date" ? true : false;
      //     console.log("special:", special);

      //     if (isSpecialDateType) {
      //       let specialDates = Booking._dateandtime.getHolidayDates(
      //         special.time_start,
      //         special.time_end
      //       );

      //       if (specialDates.length > 0) {
      //         specialDates.forEach((day) => {
      //           availableDays.push(moment(day).format("dddd").toLowerCase());
      //         });
      //       }
      //     } else {
      //       if (moment(special.date).isSame(date)) {
      //         availableDays.push(
      //           moment(special.date).format("dddd").toLowerCase()
      //         );
      //       }
      //     }
      //   });
      // }

      if (offer?.custom_options?.available?.length > 0) {
        for (const available of offer?.custom_options?.available) {
          availableDays.push(available.name);
        }
      }

      return availableDays;
    },

    getPackageAvailability: (date, offer) => {
      let availableTime = [];

      let day = moment(date, "dddd").format("dddd");

      let packageAvailability = offer?.custom_options?.available;
      if (packageAvailability && packageAvailability?.length > 0) {
        // loop available dates
        for (const available of packageAvailability) {
          if (available.name === day.toLowerCase()) {
            availableTime.push(available.time);
          }
        }

        // loop special dates
        // special day is now holiday  and block calendar
        // let specials = offer?.custom_options?.special ?? [];
        // if (specials?.length > 0) {
        //   for (const special of specials) {
        //     if (
        //       moment(special.date).format("dddd").toLowerCase() ===
        //       day.toLowerCase()
        //     ) {
        //       // push special date time start and end to list
        //       availableTime.push({
        //         start: special.time_start,
        //         end: special.time_end,
        //       });
        //     }
        //   }
        // }
      }

      return availableTime.length > 0 ? availableTime[0] : null;
    },

    filterList: (
      list,
      blockTimeOnSelectedDate,
      appForThisOffer,
      allUpcommingAppt,
      selectedOffer,
      selectedTimezone,
      selectedDate,
      coachTimezone = null
    ) => {
      return list
        .filter(
          (time) =>
            !Boolean(
              Booking._dateandtime.isTimeBlocked(
                blockTimeOnSelectedDate,
                time,
                selectedTimezone,
                coachTimezone,
                selectedOffer
              )
            )
        )
        .filter(
          (time) =>
            !Boolean(
              Booking._dateandtime.isTimeNotAvailable(
                time,
                appForThisOffer,
                selectedOffer,
                selectedTimezone
              )
            )
        )
        .filter(
          (time) =>
            !Boolean(
              Booking._dateandtime.isTimePassToday(
                time,
                coachTimezone,
                selectedTimezone
              )
            )
        )
        .filter(
          (time) =>
            !Boolean(
              Booking._dateandtime.isTimeBreakOff(time, selectedTimezone)
            )
        )
        .filter((time) =>
          Boolean(
            Booking._dateandtime.isTimeSameOnSelectedDate(time, selectedDate)
          )
        )
        .filter(
          (time) =>
            !Boolean(
              Booking._dateandtime.isTimeBookedFromOtherPackage(
                time,
                allUpcommingAppt,
                selectedTimezone,
                selectedOffer
              )
            )
        );
    },

    isWholeDayBlockedByCoach: (
      selectedOffer,
      date,
      coachTimezone = null,
      selectedTimezone = null
    ) => {
      let blockedDates = selectedOffer?.custom_options?.blocked ?? [];
      let holidayDates = selectedOffer?.custom_options?.special ?? [];

      if (blockedDates.length > 0) {
        for (const element of blockedDates) {
          const inputFormat = "YYYY-MM-DD HH:mm";
          const isBlockTypeDate = element?.type === "date" ? true : false;

          let startDate = isBlockTypeDate
            ? element.time_start
            : element.date + " " + element.time_start;
          let endDate = isBlockTypeDate
            ? element.time_end
            : element.date + " " + element.time_end;

          const sourceTimezone = coachTimezone;
          const targetTimezone = selectedTimezone;

          const clientBlockedTimeStart = moment
            .tz(
              moment(new Date(startDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD hh:mm A");

          const clientBlockedTimeEnd = moment
            .tz(
              moment(new Date(endDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD hh:mm A");

          // if blocked type is date
          if (isBlockTypeDate) {
            let xx = moment(date, dateFormat).isBetween(
              clientBlockedTimeStart,
              clientBlockedTimeEnd
            );

            // return only when true
            if (xx) {
              return true;
            }
          }

          // double check this functions, coach block 24hours should not be available in nega and positive timezone
          if (
            moment(date, dateFormat).isSame(
              moment(clientBlockedTimeStart, dateFormat).format(dateFormat)
            )
          ) {
            let isWholeDayBlocked = Booking._dateandtime.isWholeDayBlocked(
              clientBlockedTimeStart,
              clientBlockedTimeEnd
            );

            // return only when true
            if (isWholeDayBlocked) {
              return true;
            }
          }
        }
      }

      // special day is how holiday and blocked coach calendar
      if (holidayDates.length > 0) {
        for (const element of holidayDates) {
          const inputFormat = "YYYY-MM-DD HH:mm";
          const isHolidayTypeDate = element?.type === "date" ? true : false;

          let startDate = isHolidayTypeDate
            ? element.time_start
            : element.date + " " + element.time_start;
          let endDate = isHolidayTypeDate
            ? element.time_end
            : element.date + " " + element.time_end;

          const sourceTimezone = coachTimezone;
          const targetTimezone = selectedTimezone;

          const clientHolidayTimeStart = moment
            .tz(
              moment(new Date(startDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD hh:mm A");

          const clientHolidayTimeEnd = moment
            .tz(
              moment(new Date(endDate), inputFormat).format(inputFormat),
              inputFormat,
              sourceTimezone
            )
            .tz(targetTimezone)
            .format("YYYY-MM-DD hh:mm A");

          // if blocked type is date
          if (isHolidayTypeDate) {
            let xx = moment(date, dateFormat).isBetween(
              clientHolidayTimeStart,
              clientHolidayTimeEnd
            );

            // return only when true
            if (xx) {
              return true;
            }
          }

          // double check this functions, coach block 24hours should not be available in nega and positive timezone
          if (
            moment(date, dateFormat).isSame(
              moment(clientHolidayTimeStart, dateFormat).format(dateFormat)
            )
          ) {
            let isWholeDayBlocked = Booking._dateandtime.isWholeDayBlocked(
              clientHolidayTimeStart,
              clientHolidayTimeEnd
            );

            // return only when true
            if (isWholeDayBlocked) {
              return true;
            }
          }
        }
      }
    },

    isWholeDayBlocked: (start, end) => {
      const __format = "YYYY-MM-DD HH:mm";
      const startOfDay = moment(start, __format)
        .startOf("day")
        .format(__format);
      const endOfDay = moment(end, __format).endOf("day").format(__format);
      const _start = moment(new Date(start), __format).format(__format);
      const _end = moment(new Date(end), __format).format(__format);

      return moment(startOfDay).isSame(_start) && moment(endOfDay).isSame(_end);
    },
  },

  _bycoach: {
    steps: [
      {
        key: 0,
        label: "Choose Package",
        code: "choose-package",
      },

      {
        key: 1,
        label: "Client Information",
        code: "information",
      },

      {
        key: 2,
        label: "Choose Datetime",
        code: "choose-datetime",
      },

      {
        key: 3,
        label: "Summary",
        code: "summary",
      },
      {
        key: 4,
        label: "Thank you page",
        code: "thank-you",
      },
    ],

    handlePrevStep: (activeStep, setActiveStep, setShowBtns, steps) => {
      setActiveStep((prev) => {
        return activeStep === 0 ? 0 : prev - 1;
      });
      setShowBtns((prev) => {
        return {
          ...prev,
          back: activeStep <= 1 ? false : true,
          next: true,
          save: false,
        };
      });
    },

    handleNextStep: (activeStep, setActiveStep, setShowBtns, steps) => {
      setActiveStep((prev) => {
        return prev + 1;
      });

      setShowBtns((prev) => {
        return {
          ...prev,
          next: steps[activeStep].code !== "choose-datetime" ? true : false,
          save: steps[activeStep].code === "choose-datetime" ? true : false,
          back: true,
        };
      });
    },

    validateOnNext: async (
      selectedSessionTime,
      t,
      selectedDate,
      getValues,
      trigger,
      activeStep,
      selectedOffer,
      setError,
      setActiveStep,
      setShowBtns,
      steps,
      clearErrors,
      selectedClient = null,
      isEdit = false,
      extraFieldSelectedFiles = {}
    ) => {
      let error = [];

      if (activeStep === 0) {
        if (selectedOffer === null) {
          error = "package-required";
          setError("package", {
            type: "required",
            message: "Select package in a list.",
          });
        }
      }

      if (activeStep === 1) {
        // check new client fields if not existing
        if (selectedClient === null) {
          if (
            getValues("firstname").trim("") === "" ||
            getValues("firstname").length === 0
          ) {
            error = "firstname requried";
            setError("firstname", {
              type: "required",
              message: t(
                "appointment.create.form.error.field.required.firstname"
              ),
            });
          }

          if (
            getValues("lastname").trim("") === "" ||
            getValues("lastname").length === 0
          ) {
            error = "lastname requried";
            setError("lastname", {
              type: "required",
              message: t(
                "appointment.create.form.error.field.required.firstname"
              ),
            });
          }

          if (
            getValues("email").trim("") === "" ||
            getValues("email").length === 0
          ) {
            error = "email requried";
            setError("email", {
              type: "required",
              message: t(
                "appointment.create.form.error.field.required.firstname"
              ),
            });
          } else {
            let checkEmailIsValid = await trigger(["email"]);

            if (!checkEmailIsValid) {
              error = "email invalid";
              setError("email", {
                type: "required",
                message: t("appointment.create.form.error.field.invalid.email"),
              });
            }
          }
        }

        // disable extra field validation if coach create a book
        // if (selectedOffer?.fields.length > 0) {
        //   let checkHasRequired = selectedOffer?.fields.filter((x) =>
        //     Boolean(x.required)
        //   );

        //   if (checkHasRequired.length > 0) {
        //     checkHasRequired.forEach((xfield) => {
        //       let field = Helper.createSlug(xfield.name);

        //       let hasUploadedFiles = extraFieldSelectedFiles[field] ?? [];

        //       if (xfield.type === "upload") {
        //         if (hasUploadedFiles.length === 0) {
        //           // has error no files uploaded
        //           setError(field, {
        //             required: true,
        //             message: "No uploaded file",
        //           });

        //           error = `${field}-has-an-error-no-uploaded`;
        //         }
        //       } else {
        //         // has error empty fields
        //         if (!Boolean(getValues(`${field}`))) {
        //           setError(field, {
        //             required: true,
        //             message: t("booking.error.required.dynamic_field", {
        //               field,
        //             }),
        //           });

        //           error = `${field}-has-an-error`;
        //         }
        //       }
        //     });
        //   }
        // }
      }

      if (activeStep === 2) {
        if (selectedDate === null) {
          error = "date-is-required";
          setError("date", {
            required: true,
            message: "Date is a required field.",
          });
        }

        if (Object.keys(selectedSessionTime).length === 0) {
          error = "time-is-required";
          setError("time", {
            required: true,
            message: "Time is a required field.",
          });
        }

        if (isEdit) {
          if (
            getValues("reason").trim("") === "" ||
            getValues("reason").length === 0
          ) {
            error = "reason-is-required";
            setError("reason", {
              required: true,
              message: "Reason for change is a required field.",
            });
          }
        }
      }

      if (error.length > 0) {
        console.log("form has an error", error);
        return;
      }

      Booking._bycoach.handleNextStep(
        activeStep,
        setActiveStep,
        setShowBtns,
        steps
      );
      clearErrors();
    },

    handleCheckPaypalInterval: (type) => {
      if (type === "DAY") return "D";
      if (type === "WEEK") return "W";
      if (type === "MONTH") return "M";
      if (type === "YEAR") return "Y";
    },
  },

  isIntegrationActive: (selectedOffer, name) => {
    let activeIntegration = selectedOffer?.enabled_integrations ?? [];

    if (activeIntegration.length === 0) return false;
    for (const data of activeIntegration) {
      if (data?.integration?.name.toLowerCase() === name?.toLowerCase()) {
        return true;
      }
    }

    return false;
  },

  nonFreePackageHasAvailablePayment: (
    offer,
    integrations,
    from = "booking"
  ) => {
    let hasPayment = Number(offer.price) ? true : false;

    if (!hasPayment) return false;
    if (integrations.length === 0) return true;

    const paymentInPackage = offer?.custom_options?.enable_payments ?? [];
    const isBankTransfer =
      offer?.custom_options?.bank_transfer?.enable ?? false;

    for (const availablePayment of integrations) {
      let name =
        from === "booking"
          ? availablePayment?.integration?.name
          : availablePayment?.name;

      if (isBankTransfer && name?.toLowerCase() === "bank transfer") {
        return false;
      }

      if (paymentInPackage?.includes(name?.toLowerCase())) {
        return false;
      }
    }

    return true;
  },

  groupApptsByDate: (appointments) => {
    const groupedAppointments = appointments.data?.reduce(
      (groupedData, appointment) => {
        const date = new Date(appointment.date).toLocaleDateString("en-US"); // Format date for grouping
        groupedData[date] = groupedData[date] || []; // Initialize array for the date if not already present
        groupedData[date].push(appointment);
        return groupedData;
      },
      {}
    );

    // Convert object to array
    const groupedAppointmentsArray = Object.entries(groupedAppointments).map(
      ([date, appointmentsForDate]) => ({
        date,
        appointments: appointmentsForDate,
      })
    );

    return groupedAppointmentsArray;
  },

  groupApptsByDateMonth: (appointments) => {
    const groupedAppointments = appointments.data?.reduce(
      (groupedData, appointment) => {
        const date = moment(new Date(appointment.date)).format("MMMM YYYY"); // Format date for grouping
        groupedData[date] = groupedData[date] || []; // Initialize array for the date if not already present
        groupedData[date].push(appointment);
        return groupedData;
      },
      {}
    );

    // Convert object to array
    const groupedAppointmentsArray = Object.entries(groupedAppointments).map(
      ([date, appointmentsForDate]) => ({
        date,
        appointments: appointmentsForDate,
      })
    );

    return groupedAppointmentsArray;
  },
};
export default Booking;
