import React from "react";
import { Navigate } from "react-router-dom";
import ClientPortalGuard from "src/components/guard/ClientPortalGuard";
import LogoutClient from "src/components/LogoutClient";
import ClientPortalBaseLayout from "src/layout/menu/client-portal";

const ClientPortalGuardedRoutes = [
  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/",
    component: () => <Navigate to="/client/appointments" />,
    show: false,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/appointments",
    component: React.lazy(() =>
      import("src/views/clients-portal/appointments")
    ),
    name: "Appointments",
    show: true,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/packages",
    component: React.lazy(() => import("src/views/clients-portal/packages")),
    name: "Packages",
    show: false,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/digital-products",
    component: React.lazy(() =>
      import("src/views/clients-portal/digital-products")
    ),
    name: "Digital Products",
    show: true,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/digital-products/details/:packageId",
    component: React.lazy(() =>
      import("src/views/clients-portal/digital-products/details")
    ),
    name: "Digital Products",
    show: false,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/courses",
    component: React.lazy(() => import("src/views/clients-portal/courses")),
    name: "Courses",
    show: true,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/packages/details/:type/:packageId",
    component: React.lazy(() =>
      import("src/views/clients-portal/packages/details")
    ),
    name: "Packages Details",
    show: false,
  },
  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/courses/details/:packageId",
    component: React.lazy(() =>
      import("src/views/clients-portal/courses/details")
    ),
    name: "Courses Details",
    show: false,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/contents",
    component: React.lazy(() => import("src/views/clients-portal/contents")),
    name: "Content",
    show: true,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/surveys",
    component: React.lazy(() => import("src/views/clients-portal/surveys")),
    name: "Surveys",
    show: true,
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/settings",
    component: React.lazy(() =>
      import("src/views/clients-portal/settings/account")
    ),
  },

  {
    exact: true,
    guard: ClientPortalGuard,
    layout: ClientPortalBaseLayout,
    path: "/client/logout",
    component: () => <LogoutClient />,
  },
];

export default ClientPortalGuardedRoutes;
