import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

import http from "src/utils/http";
import Logo from "src/components/Logo";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller as Fc } from "react-hook-form";
import TreffasStyle from "src/utils/TreffasStyle";

import SideBg from "../SideBg";
import { useTranslation } from "react-i18next";
import { Users } from "src/context";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "src/context/SnackbarContext";
import Page from "src/components/Page";

const LoginClient = () => {
  const themes = useTheme();
  const [isProcess, setIsProcess] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mixpanel } = useContext(Users);
  const { showSnackbar } = useSnackbarContext();

  const Schema = Yup.object({
    email: Yup.string()
      .email(t("login.form.validation.email_invalid"))
      .required(t("login.form.validation.email_required")),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      // organization: "",
      email: "",
    },
  });

  const onLogin = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/login/client-portal", {
        ...values,
      })
      .then(({ data }) => {
        if (data.message === "email-invalid") {
          setError("email", {
            required: true,
            message: "Invalid email address.",
          });
        }

        if (data.message === "success") {
          showSnackbar(
            "Verification code successsfully send to your email.",
            "success"
          );
          navigate(
            `/login/client-portal/verify?email=${values.email}&token=${data.token}`
          );
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    mixpanel.track("Login Client Portals Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Client Login Portal">
      {/* show on small screen as logo */}
      <Box
        sx={(themes) => ({
          height: "80px",
          alignItems: "center",
          [themes.breakpoints.up("md")]: {
            alignItems: "center",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
            pl: "99px",
            display: "none",
          },
          [themes.breakpoints.down("md")]: {
            justifyContent: "flex-start",
            pl: "24px !important",
            display: "flex",
          },
        })}
      >
        <Logo />
      </Box>
      <Box
        sx={{
          minHeight: { xs: "calc(100vh - 110px)", md: "calc(100vh - 0px)" },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            pt: {
              md: "120px",
            },
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box>
              <Box mb={"12px"}>
                <Typography
                  className="heading-2"
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Folyeo Client
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                Please login to continue
              </Typography>
            </Box>

            {/* login form */}
            <Box
              sx={{
                width: "440px",
                mt: "32px",
              }}
            >
              <form onSubmit={handleSubmit(onLogin)}>
                <Box>
                  <FormControl fullWidth variant="standard">
                    <Typography
                      sx={{
                        ...TreffasStyle.formLabel,
                        fontFamily: "var(--font-work-sans)",
                      }}
                    >
                      {t("login.form.email")}
                    </Typography>

                    <Fc
                      name="email"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          placeholder={t("login.form.placeholder.email")}
                          type="text"
                          sx={TreffasStyle.formInput(themes)}
                          error={Boolean(errors.email)}
                          helperText={errors.email?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Box>

                {errors.submit && (
                  <Box>
                    <FormHelperText
                      error
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {errors.submit.message}
                    </FormHelperText>
                  </Box>
                )}

                <Box sx={{ my: "16px" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      borderRadius: "10px",
                      minHeight: "44px",
                      backgroundColor: "var(--bg-black)",
                      fontFamily: "var(--font-work-sans)",
                    }}
                    disabled={isProcess}
                    startIcon={
                      isProcess && (
                        <CircularProgress color="inherit" size={15} />
                      )
                    }
                  >
                    Send me a confirmation code
                  </Button>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#000",
                      fontFamily: "var(--font-work-sans)",
                    }}
                  >
                    Are you a coach?
                    <Typography
                      variant="subtitle"
                      sx={{
                        cursor: "pointer",
                        marginLeft: 0.5,
                        marginRight: 0.5,
                        fontWeight: 500,
                        color: "#D97855",
                        fontFamily: "var(--font-work-sans)",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Click here to sign in.
                    </Typography>
                  </Typography>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>

        <Box
          sx={() => ({
            overflow: "hidden",
            width: "1100px",
            [themes.breakpoints.down("lg")]: {
              display: "none",
            },
          })}
        >
          <SideBg />
        </Box>
      </Box>
    </Page>
  );
};

export default LoginClient;
